import * as Yup from "yup";

export function initialValues() {
    return {
        cardcode: "",
        medico: "",
    }
}

export function validationSchema() {
    return Yup.object({
        cardcode: Yup.string()
            .matches(/^C\d{5}$/, "El código debe comenzar con 'C' y tener exactamente 5 caracteres numéricos sin espacios")
            .required("El código es obligatorio"),
        medico: Yup.string().required("El campo médico es obligatorio")
    });
}