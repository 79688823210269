/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Doctor } from '../../../../api';
import { size, map } from 'lodash';
import { Loader, Pagination, Search, Popup } from 'semantic-ui-react';
import { AltaClienteItem } from '../AltaClienteItem/AltaClienteItem';
import { useAuth } from '../../../../hooks';

const DoctorController = new Doctor();


export function ListAltaCliente(props) {
  const { reload, onReload, onClose } = props;
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const { user: { email } } = useAuth();
  const CorreoAsesor = email;

  const fetchDocs = async (page) => {
    try {
      const response = await DoctorController.getDocByAsesor(CorreoAsesor, { page, limit: 10 });
      return response;
    } catch (error) {
      console.error(error);
      return { docs: [], limit: 0, page: 0, pages: 0, totalPages: 0 };
    }
  };
  const loadDocs = async () => {
    const response = await fetchDocs(page);
    setDocs(response.docs);
    setPagination({
      limit: response.limit,
      page: response.page,
      pages: response.pages,
      total: response.totalPages,
    });
  };

  const loadAllDocs = async () => {
    let allDocs = [];
    for (let i = 1; i <= pagination.total; i++) {
      const response = await fetchDocs(i);
      allDocs = allDocs.concat(response.docs);
    }
    return allDocs;
  };

  useEffect(() => {
    loadDocs();
  }, [page, reload]);

  useEffect(() => {
    if (searchTerm) {
      (async () => {
        const allDocs = await loadAllDocs();
        setDocs(allDocs);
      })();
    } else {
      loadDocs();
    }
  }, [searchTerm, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  const handleSearchChange = (_, { value }) => {
    setSearchTerm(value);
  };

  const filterDocs = () => {
    if (!searchTerm) {
      return docs;
    } else {
      return docs.filter(doc =>
        (doc.folio && doc.folio.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (doc.nombre && doc.nombre.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (doc.cedula && doc.cedula.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  };

  if (!docs) return <Loader active inline="centered" />;
  if (size(docs) === 0) return 'No hay doctores';

  return (
    <div className='list-cotizaciones'>
      <Popup trigger={<Search
        onSearchChange={handleSearchChange}
        value={searchTerm}
        placeholder="Buscar..."
        showNoResults={false}
      />}
        header='Busca por:'
        content='Folio, Nombre ó Cedula'
      />
      <br />
      {map(filterDocs(), doc => (
        <AltaClienteItem key={doc._id} doc={doc} onReload={onReload} onClose={onClose} />
      ))}
      <div className='list-cotizaciones__pagination'>
        <Pagination
          totalPages={pagination.total}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
