import * as Yup from "yup";

export function initialValues(equal) {
    return {
        cliente: equal?.cliente || "",
        asesor: equal?.asesor || "",
        asesornom: equal?.asesornom || "",
        padecimiento: equal?.padecimiento || "",
        base: equal?.base || "",
        activos: equal?.activos || "",
        especialidad: equal?.especialidad || "",
        cardcode: equal?.cardcode || "",
        precioUni: equal?.precioUni || '',
        codigo: equal?.codigo || '',
    }
}

export function validationSchema() {
    return Yup.object({
        codigo: Yup.string().required(true)
        });
}