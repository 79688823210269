'use client'

import React, { useState, useEffect } from 'react'
import { Container, List, Search, Loader, Pagination } from 'semantic-ui-react'
import { Bioequal } from '../../../../../api'
import { useAuth } from '../../../../../hooks'

const BioequalControllador = new Bioequal()

export function AsesorCargaFirmaView() {
  const [bioequals, setBioequals] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const { user: { email } } = useAuth()
  const itemsPerPage = 10

  useEffect(() => {
    const fetchBioequals = async () => {
      try {
        setLoading(true)
        const response = await BioequalControllador.GetFirmaBioequal({ page, limit: itemsPerPage })
        setBioequals(response.docs)
        setTotalPages(response.totalPages)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching bioequals:', error)
        setLoading(false)
      }
    }

    fetchBioequals()
  }, [email, page])

  const handleSearchChange = (_, { value }) => {
    setSearchTerm(value)
    setPage(1)
  }

  const handlePageChange = (_, { activePage }) => {
    setPage(activePage)
  }

  const filterBioequals = () => {
    if (!searchTerm) {
      return bioequals
    } else {
      return bioequals.filter(bioequal =>
        (bioequal.cardcode && bioequal.cardcode.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (bioequal.medico && bioequal.medico.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      )
    }
  }

  if (loading) return <Loader active inline="centered" />

  const filteredBioequals = filterBioequals()

  return (
    <Container>
      <Search
        onSearchChange={handleSearchChange}
        value={searchTerm}
        placeholder="CardCode o Nombre"
        showNoResults={false}
      />
      <List divided relaxed>
        {filteredBioequals.map((bioequal) => (
          <List.Item key={bioequal._id}>
            <List.Content>
              <List.Header>{bioequal.cardcode}</List.Header>
              <List.Description>
                Nombre: {bioequal.medico || 'N/A'}<br />
                File: {bioequal.files || 'N/A'}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
      {!searchTerm && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Pagination
            activePage={page}
            onPageChange={handlePageChange}
            totalPages={totalPages}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
          />
        </div>
      )}
    </Container>
  )
}