import React  from 'react'
import { Form, Container, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './AsesorForm.form'
import { Bioequal } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./AsesorForm.scss";


const equalController = new Bioequal();

export function AsesorBioequalForm(props) {


    const { onClose, onReload, bioequal } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;

    const formik = useFormik({
        initialValues: initialValues(bioequal, CorreoAsesor, nombreCompleto),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {

                    const data = {
                        cliente: bioequal.cliente
                    };
                    
                    if (!bioequal) {
                        console.log("Esto no deberia de pasar")
                    } else {
                        await equalController.UpdateAsesorCotizacion(accessToken, bioequal._id, data);
                    }
                    onClose();
                    onReload();
                
            } catch (error) {
                console.log("entre al error")
                console.error(error);
            }
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='cotizacion-principal_info'>
            <p>Cotización: <span className='cotizacion-principal_info-dxp'>{bioequal.folio}</span></p>
            <p>Cardcode: <span className='cotizacion-principal_info-dxp'>{bioequal.cardcode}</span></p>
            <p>Nombre del Cliente: <span className='cotizacion-principal_info-cliente'>{bioequal.cliente}</span></p>
            <p>Especialidad: <span className='cotizacion-principal_info-especialidad'>{bioequal.especialidad}</span></p>
            <p>Base: <span className='cotizacion-principal_info-base'>{bioequal.base}</span></p>
            <p>Vía de administración: <span className='cotizacion-principal_info-base'>{bioequal.viauso}</span></p>
            <p>Activos: <span className='cotizacion-principal_info-activos'>{bioequal.activos}</span></p>
            <p>Padecimiento: <span className='cotizacion-principal_info-padecimiento'>{bioequal.padecimiento}</span></p>
            <p>Modo de uso: <span className='cotizacion-principal_info-padecimiento'>{bioequal.modo}</span></p>
            <p>Restringida: <span className='cotizacion-principal_info-nombre'>{bioequal.restringida ? 'Si' : 'No'}</span></p>
            <p>Fecha creada: <span className='cotizacion-principal_info-padecimiento'>{bioequal.created_at}</span></p>
            <Container className='precio_unitario'>
                    <Table className='table-precio' celled>
                        <TableHeader>
                            <TableRow>
                            <TableHeaderCell>Presentaciones</TableHeaderCell>
                            <TableHeaderCell>Precio</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                            <TableCell>Precio Unitario</TableCell>
                            <TableCell>{bioequal.precioUni}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Container>
            </Container>
            <Form.Button type='Submit' primary fluid loading={formik.isSubmitting}>
                Enviar
            </Form.Button>
        </Form>
    )
}
