import React, {useState} from 'react'
import { BasicModal } from '../../../components/Shared'
import { Button, Tab } from 'semantic-ui-react'
import "./altacliente.scss";
import {AltaClienteForm} from "../../../components/Admin/Asesor"
import { ListAltaCliente } from '../../../components/Admin/Asesor/ListAltaCliente/ListAltaCliente';

export function Altacliente() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);
  const panes=[
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListAltaCliente reload={reload} onReload={onReload}/>
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <div className='cliente-page'>
        <Button className='cliente-page_new' value="nueva" primary onClick={()=> onOpenCloseModal('nueva')}>Nuevo Cliente</Button>
        <Tab menu={{secondary: true}} panes={panes}/> 
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title='Alta Cliente'>
        <AltaClienteForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  )
}
