import {ENV} from "../utils";

export class Post{
    baseApi=ENV.BASE_API;
    async getPost(page=1, limit= 10){
        try {
            const pageFilter = `page=${page}`;
            const limitFilter = `limit=${limit}`;
            const url = `${this.baseApi}/${ENV.API_ROUTES.BLOG}?${pageFilter}&${limitFilter}`;
            const response = await fetch(url);
            const result = await response.json();
            if(response.status !== 200) throw result;
            return result;
        }catch(error) {
            throw error;
        }
    }
    async createPost(accessToken, data){
        try{
            const formData = new FormData();
            Object.keys(data).forEach((key) =>{
                formData.append(key,data[key]);
            });
            if(data.file){
                formData.append("miniature",data.file);
            }
            const url = `${this.baseApi}/${ENV.API_ROUTES.BLOG}`
            const params = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: formData,
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if(response.status !== 200) throw result;
            return result;
        }catch(error){
            throw error;
        }
    }
    async updatePost(accessToken, idPost, data){
        try {
            const formData = new FormData();
            Object.keys(data).forEach((key) =>{
                formData.append(key,data[key]);
            });
            if(data.file){
                formData.append("miniature",data.file);
            }
            const url = `${this.baseApi}/${ENV.API_ROUTES.BLOG}/${idPost}`
            const params = {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: formData,
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if(response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }
    async deletePost(accessToken, idPost){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.BLOG}/${idPost}`
            const params={
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if(response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }
    async getPosts(path){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.BLOG}/${path}`
            const response = await fetch(url);
            const result = await response.json();
            if(response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }
}