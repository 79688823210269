import React, { useCallback, useState, useEffect } from 'react'
import { Form, Label, Header, Icon, Container } from 'semantic-ui-react'
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './AsesorCargaFirmaForm.form'
import { Bioequal } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./AsesorForm.scss";

const BioequalControllador = new Bioequal();

export function AsesorCargaFirmaForm(props) {
    const { onClose, onReload } = props;
    const { accessToken } = useAuth();
    const [myFiles, setMyFiles] = useState([]);
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;
    const [availableCardcodes, setAvailableCardcodes] = useState([]);


    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            console.log(formValue);
            try {
                if (myFiles === null || myFiles.length === 0) {
                    alert("Porfavor ingresa al menos un archivo");
                    
                }else{
                    const data = {
                        cardcode: formValue.cardcode,
                        medico : formValue.medico,
                        asesor: CorreoAsesor,
                        asesornom: nombreCompleto,
                        file : myFiles
                    };
                    try {
                        await BioequalControllador.CreateFirmaBioequal(accessToken, data);
                    } catch (error) {
                        if (error.status === 400) {  // Verifica si es un error 400
                            alert('Ocurrió un error. Por favor, verifica tu conexión a internet y vuelve a intentar la subida de archivos.');
                            onClose();
                            onReload();
                        } else {
                            console.log('Error inesperado:', error);
                        }
                    }
                    onClose();
                    onReload();
                }

            } catch (error) {
                console.error(error);
            }
        }
    });

    useEffect(() => {
        const fetchCardcodes = async () => {
            try {
                // Asume que tienes un método para obtener los cardcodes disponibles
                const response = await BioequalControllador.getAvailableCardcodes();
                const cardcodeOptions = response.docs.map(cardcode => ({
                    cardcode: cardcode.cardcode,
                    medico: cardcode.medico,
                    files: cardcode.files
                }));
                setAvailableCardcodes(cardcodeOptions);
                console.log(cardcodeOptions);
            } catch (error) {
                console.error('Error fetching cardcodes:', error);
            }
    
        };
        fetchCardcodes();
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const fileUrl = URL.createObjectURL(file);
        formik.setFieldValue("documentos", fileUrl);
        formik.setFieldValue("fileDocumentos", file);
        setMyFiles([...myFiles, ...acceptedFiles])
        console.log(myFiles);
    }, [myFiles, formik]);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'image/png': ['.jpeg', '.png', '.jpg'],
            'application/pdf': ['.doc', '.docx', '.pdf', '.xls', '.xlsx']
        },
        onDrop,
    });

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const files = myFiles.map(file => (
        <span key={file.path}>
            {file.path}
            <Icon onClick={removeFile(file)} name='trash alternate' />
        </span>
    ))

    return (
        <>
        <Form autoComplete="off" onSubmit={formik.handleSubmit} className='form'>
            <Container className='form-cotizacion__primer'>
                {/* <Form.Input label="Cardcode" name="cardcode" placeholder="Cardcode del médico" maxLength={6} onChange={(e) => {const uppercasedValue = e.target.value.toUpperCase(); formik.setFieldValue("cardcode", uppercasedValue); }} value={formik.values.cardcode} error={formik.errors.cardcode} /> */}
                <Form.Input label="Cardcode" name="cardcode" placeholder="Cardcode del médico responsable" maxLength={6}
                onChange={(e) => {
                    // Actualiza el valor de cardcode en formik
                    // formik.handleChange(e);
                    const uppercasedValue = e.target.value.toUpperCase();
                    formik.setFieldValue("cardcode", uppercasedValue);
                    // Obtiene el valor ingresado en el campo cardcode
                    const cardcodeValue = e.target.value;
            
                    // Busca coincidencias en el arreglo de cardcodes
                    const found = availableCardcodes.find(
                        cardcode => cardcode.cardcode === cardcodeValue
                    );
            
                    // Si encuentra coincidencia, actualiza el nombre del médico
                    if (found) {
                        formik.setFieldValue('medico', found.medico);
                    } else {
                        formik.setFieldValue('medico', '');
                    }
                }}
                value={formik.values.cardcode} error={formik.errors.cardcode} />
                <Form.Input label="Nombre" name="medico" placeholder="Nombre del médico" onChange={(e) => {const uppercasedValue = e.target.value; formik.setFieldValue("medico", uppercasedValue); }} value={formik.values.medico} error={formik.errors.medico} />
                <h4>Agregar Archivos</h4>
                    <div className='soporte-form__documentos' {...getRootProps()}>
                        <Label>
                            <Header icon>
                                <Icon name='file text outline' />
                                
                            </Header>
                            <input {...getInputProps()} name="documentos" />
                            {isDragAccept && (<p>Imágenes (jpg, png, jpeg), Archivos (.doc, .docx, .pdf, .xls, .xlsx)</p>)}
                            {isDragReject && (<p>Otros tipos de Archivos son Rechazados</p>)}
                            {isDragActive && (<p>Suelta varios Archivos Aquí...</p>)}
                        </Label>
                    </div>
                    {files}
                    <br/>
                </Container>
               <Form.Button type='submit' primary fluid loading={formik.isSubmitting} >
                    Cargar Firma
                </Form.Button>
            </Form>
        </>
    )
}
