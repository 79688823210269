import React, { useState } from 'react'
import { Button, Icon, Label, Confirm } from 'semantic-ui-react';
import { BasicModal } from '../../../../Shared';
import "./AsesorItem.scss";
import { AsesorBioequalForm, AsesorView } from '../AsesorBioequalForm2';
import { Bioequal } from '../../../../../api';
import { useAuth } from '../../../../../hooks'

const equalController = new Bioequal();

export function AsesorBioequalItem(props) {
  const { bioequal, onReload } = props;
  const { accessToken } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const date = new Date(bioequal.created_at);
  const createdate = date.toLocaleDateString();
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseModal2 = () => setShowModal2((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openVerDoc = () => {
    setTitleModal(`Folio de fórmula Bioequal: ${bioequal.folio}`);
    onOpenCloseModal();
  }
  
  const openFormBioequal = () => {
    setTitleModal(`Folio de Cotización Bioequal: ${bioequal.folio}`);
    onOpenCloseModal2();
  }

  const openDesactivateActivateConfirm = () => {
    setConfirmMessage(`Borrar Formula Bioequal ${bioequal.folio}`);
    onOpenCloseConfirm();
  }

  const CancelCliente = async () => {
    try {
      await equalController.CancelBioequalAsesor(accessToken, bioequal._id)
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error)
    }
}

  let contentView = <AsesorView onClose={onOpenCloseModal} onReload={onReload} bioequal={bioequal} />;
  let contentForm = <AsesorBioequalForm onClose={onOpenCloseModal2} onReload={onReload} bioequal={bioequal} />;

  let getStatusGeneralColor;

  if (bioequal.StatusGeneral === 'Pendiente') {
    getStatusGeneralColor = 'orange'
  } else if (bioequal.StatusGeneral === 'Cancelado') {
    getStatusGeneralColor = 'red';
  } else if (bioequal.StatusGeneral === 'Finalizado') {
    getStatusGeneralColor = 'green'
  }

  

  return (
    <>
      <div className='cotizacion-item'>
         <div className='column'>
          <p className='cotizacion-item__info'>
            <span className='cotizacion-item__info-label'>Folio Cliente:</span>
            <span className='cotizacion-item__info-valor'>{bioequal.folio}</span><br />
            <span className='cotizacion-item__info-label'>Creado:</span>
            <span className='cotizacion-item__info-valor'>{createdate}</span><br/>
          </p>
        </div>
        <div className='column'>
          <p className='cotizacion-item__info'>
            <span className='cotizacion-item__info-label'>Cliente:</span>
            <span className='cotizacion-item__info-valor'>{bioequal.cliente}</span>
            <span className='cotizacion-item__info-label'>Cardcode:</span>
            <span className='cotizacion-item__info-valor'>{bioequal.cardcode}</span>
          </p>
        </div>
            
        <div className='column'>
          <p className='cotizacion-item__info'>
            <label className='estatus_gn'>Estatus General: <Label className={`cotizacion-item__info-statusinde`} color={getStatusGeneralColor}>
              {bioequal.StatusGeneral}
            </Label></label>
            <label className='estatus_gn'>Estatus Investigación y Desarrollo: <Label className={`cotizacion-item__info-statusinde`} color={bioequal.StatusGeneral === "Cancelado" ? "red" : (bioequal.sIyD ? 'green' : 'orange')}>
              { bioequal.StatusGeneral === "Cancelado" ? "Cancelado" : (bioequal.sIyD ? 'Finalizado' : 'Pendiente')}
            </Label></label>
            <label className='estatus_gn'>Estatus cotización Operaciones: <Label className={`cotizacion-item__info-statusinde`} color={bioequal.StatusGeneral === "Cancelado" ? "red" : (bioequal.sOpcoti ? 'green' : 'orange')}>
              { bioequal.StatusGeneral === "Cancelado" ? "Cancelado" : (bioequal.sOpcoti ? 'Finalizado' : 'Pendiente')}
            </Label></label>
            <label className='estatus_gn'>Estatus Confirmacion de alta asesor: <Label className={`cotizacion-item__info-statusinde`} color={bioequal.StatusGeneral === "Cancelado" ? "red" : (bioequal.sAse ? 'green' : 'orange')}>
              { bioequal.StatusGeneral === "Cancelado" ? "Cancelado" : (bioequal.sAse ? 'Finalizado' : 'Pendiente')}
            </Label></label>
            <label className='estatus_gn'>Estatus Operación Final: <Label className={`cotizacion-item__info-statusinde`} color={bioequal.StatusGeneral === "Cancelado" ? "red" : (bioequal.sOpAlta ? 'green' : 'orange')}>
              { bioequal.StatusGeneral === "Cancelado" ? "Cancelado" : (bioequal.sOpAlta ? 'Finalizado' : 'Pendiente')}
            </Label></label>
          </p>
        </div>
        <div style={{ display: 'flex', gap: '0' }}>
            {bioequal.StatusGeneral !== 'Cancelado' ? (bioequal.sOpcoti && !bioequal.sAse && (
            <Button icon primary onClick={openFormBioequal}>
              <Icon name='edit'/>
            </Button>
              )) : ''}
            <Button icon primary onClick={openVerDoc}>
              <Icon name='eye' />
            </Button>
            {bioequal.StatusGeneral !== 'Cancelado' ? (bioequal.sOpcoti && !bioequal.sAse && (
            <Button icon color='red' onClick={openDesactivateActivateConfirm}>
              <Icon name='trash alternate outline' />
            </Button>
          )) : ''}
        </div>
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        {contentView}
      </BasicModal>
      <BasicModal show={showModal2} close={onOpenCloseModal2} title={titleModal}>
        {contentForm}
      </BasicModal>
      <Confirm open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={CancelCliente} content={confirmMessage} size='mini' />
    </>
  )
}
