import React from 'react'
import { Container, TableRow, TableBody, TableHeader, TableHeaderCell, Table, TableCell } from 'semantic-ui-react';


export function AsesorView(props) {
  const { bioequal } = props;

  return (
    <div className='cotizacion-principal'>
      <Container className='cotizacion-principal_info'>
        <p>Cotización: <span className='cotizacion-principal_info-dxp'>{bioequal.folio}</span></p>
        <p>Cardcode: <span className='cotizacion-principal_info-dxp'>{bioequal.cardcode}</span></p>
        <p>Nombre del Cliente: <span className='cotizacion-principal_info-cliente'>{bioequal.cliente}</span></p>
        <p>Especialidad: <span className='cotizacion-principal_info-especialidad'>{bioequal.especialidad}</span></p>
        <p>Genero del paciente: <span className='cotizacion-principal_info-base'>{bioequal.genero}</span></p>
        <p>Base: <span className='cotizacion-principal_info-base'>{bioequal.base}</span></p>
        <p>Vía de administración: <span className='cotizacion-principal_info-base'>{bioequal.viauso}</span></p>
        <p>Activos: <span className='cotizacion-principal_info-activos'>{bioequal.activos}</span></p>
        <p>Padecimiento: <span className='cotizacion-principal_info-padecimiento'>{bioequal.padecimiento}</span></p>
        <p>Modo de uso: <span className='cotizacion-principal_info-activos'>{bioequal.modo}</span></p>
        {bioequal.motivo && <p>Motivo de cancelación: <span className='cotizacion-principal_info-nombre'>{bioequal.motivo}</span></p>}
        <p>Restringida: { bioequal.restringida ? 'Sí' : 'No'}</p>
      </Container>
      <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell>Presentaciones</TableHeaderCell>
                        <TableHeaderCell>Precio</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                        <TableCell>Precio Unitario</TableCell>
                        <TableCell>{bioequal.precioUni}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Container>
            {/* {bioequal.files?.length > 0 && (
                bioequal.files.map((file, index) => (
                  <a href={ENV.DOCTORDOCS+"/"+file} download={ENV.DOCTORDOCS+file} ><p>Archivo: <span className='cotizacion-principal_info-created_at'>{ENV.DOCTORDOCS}/{file}</span></p></a>
                ))
        )}   */}
    </div>

  )
}
