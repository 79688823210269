import React, { useCallback } from 'react';
import { Form, Image } from "semantic-ui-react";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./PerfilForm.form";
import { useDropzone } from "react-dropzone";
import { image } from '../../../../assets';
import { User } from '../../../../api';
import { useAuth } from '../../../../hooks';
import { ENV } from '../../../../utils';
import "./PerfilForm.scss";

const userController = new User();

export function PerfilForm(props) {
    const { close, onReload, user } = props;
    const { accessToken } = useAuth();
    const formik = useFormik({
        initialValues: initialValues(user),
        validationSchema: validationSchema(user),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                if (!user) { // Esta parte se debe de retirar ya que para llegar aqui se actualizará unicamente
                    await userController.createUser(accessToken, formValue);
                } else {
                    await userController.updateUser(accessToken, user._id, formValue);
                }
                console.log("llegue aqui")
                onReload();
                close();
            } catch (error) {
                console.error(error);
            }
        }
    });

// const roleOptions = [
//     { key: "asesor", text: "Asesor", value: "user" },
//     { key: "admin", text: "Administrador", value: "admin" },
//     { key: "iyd", text: "Investigación y Desarrollo", value: "iyd" },
//     { key: "ope", text: "Operaciones", value: "ope" },
//     { key: "com", text: "Comercial", value: "com" },
//     { key: "conta", text: "Contabilidad", value: "conta" },
//     { key: "sistemas", text: "Sistemas", value: "sistemas" },
//     {key: "marketing", text: "Marketing", value: "marketing"}
// ];

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const fileUrl = URL.createObjectURL(file);
        formik.setFieldValue("avatar", fileUrl);
        formik.setFieldValue("fileAvatar", file);
        // eslint-disable-next-line
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [".jpg", ".jpeg", ".png"],
        },
        onDrop
    });

    const getAvatar = () => {
        if (formik.values.fileAvatar) {
            return formik.values.avatar;
        } else if (formik.values.avatar) {
            return `${ENV.USUSARIOS}/${formik.values.avatar}`;
        }
        return image.noAvatar;
    };

    return (
        <Form autoComplete='new-form' className='user-form' onSubmit={formik.handleSubmit}>
            <div className='user-form__avatar' {...getRootProps()}>
                <input {...getInputProps()} name='avatar' />
                <Image avatar size='small' src={getAvatar()} />
            </div>
            <Form.Group widths="equal">
                <Form.Input type="text" name="firstname" autoComplete='off' placeholder="Nombre(s)"  onChange={formik.handleChange} value={formik.values.firstname} error={formik.errors.firstname} />
                <Form.Input type="text" name="lastname" autoComplete="off" placeholder="Apellidos" onChange={formik.handleChange} value={formik.values.lastname} error={formik.errors.lastname} />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Input name="email" autoComplete="off" placeholder="Correo" readOnly={true} onChange={formik.handleChange} value={formik.values.email} error={formik.errors.email} />
                </Form.Group>
            <h5>Cambiar Contraseña</h5>
            <Form.Input type='password' name="password" autoComplete='new-password' placeholder="Contraseña" onChange={formik.handleChange} value={formik.values.password} error={formik.errors.password} />
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                {user ? "Actualizar Usuario" : "Crear usuario"}
            </Form.Button>
        </Form>
    );
}