import React, { useState } from 'react'
import { Button, Icon, Label, Confirm } from 'semantic-ui-react';
import { BasicModal } from '../../../Shared';
import "./AltaClienteComeItem.scss";
import { AltaClienteComeView, AltaClienteComeForm } from '../AltaClienteComeForm';
import { Doctor } from '../../../../api';
import { useAuth } from '../../../../hooks'

const DoctorControllador = new Doctor();

export function AltaClienteComeItem(props) {
  const { doc, onReload } = props;
  const { accessToken } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const date = new Date(doc.created_at);
  const createdate = date.toLocaleDateString();
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseModal2 = () => setShowModal2((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openVerDoc = () => {
    setTitleModal(`Folio de Doctor: ${doc.folio}`);
    onOpenCloseModal();
  }
  const openFormDoc = () => {
    setTitleModal(`Folio de Doctor: ${doc.folio}`);
    onOpenCloseModal2();
  }
  const openDesactivateActivateConfirm = () => {
    setConfirmMessage(`Borrar Doctor ${doc.nombre}`);
    onOpenCloseConfirm();
  }

  const CancelCliente = async () => {
      try {
        await DoctorControllador.CancelDoctor(accessToken, doc._id);
        onReload();
        onOpenCloseConfirm();
      } catch (error) {
        console.log("Error: " + error)
      }
  }

  const CheckDoctor = async () => {
    try {
      await DoctorControllador.CheckDoctor(accessToken, doc._id);
      onReload();
    } catch (error) {
      console.log("Error: " + error)
    }
}
  


  // const getStatusColor = (status) => status ? 'green' : 'orange';


  let contentView = <AltaClienteComeView onClose={onOpenCloseModal} onReload={onReload} doc={doc} />;
  let contentForm = <AltaClienteComeForm onClose={onOpenCloseModal2} onReload={onReload} doc={doc} />;

  let getStatusGeneralColor;
  let colorstatusasesor;
  let colorstatuscome;

  doc.checkA ? colorstatusasesor = 'green' : colorstatusasesor = 'orange';
  doc.checkC ? colorstatuscome = 'green' : colorstatuscome = 'orange';

  if (doc.estatusgeneral === 'Pendiente') {
    getStatusGeneralColor = 'orange'
  } else if (doc.estatusgeneral === 'Cancelado') {
    getStatusGeneralColor = 'red';
    colorstatusasesor = 'red';
    colorstatuscome = 'red';
  } else if (doc.estatusgeneral === 'Finalizado') {
    getStatusGeneralColor = 'green'
  }

  

  return (
    <>
      <div className='cotizacion-item'>
         <div className='column'>
          <p className='cotizacion-item__info'>
            <span className='cotizacion-item__info-label'>Folio Cliente:</span>
            <span className='cotizacion-item__info-valor'>{doc.folio}</span><br />
            <span className='cotizacion-item__info-label'>Creado:</span>
            <span className='cotizacion-item__info-valor'>{createdate}</span><br/>
            <span className='cotizacion-item__info-label'>Asesor:</span>
            <span className='cotizacion-item__info-valor'>{doc.asesor}</span><br/>
          </p>
        </div>
        <div className='column'>
          <p className='cotizacion-item__info'>
            <span className='cotizacion-item__info-label'>Cliente:</span>
            <span className='cotizacion-item__info-valor'>{doc.nombre}</span>
            <span className='cotizacion-item__info-label'>Cardcode:</span>
            <span className='cotizacion-item__info-valor'>{doc.cardcode}</span>
            <span className='cotizacion-item__info-label'>Correo:</span>
            <span className='cotizacion-item__info-valor'>{doc.email}</span>
            <span className='cotizacion-item__info-label'>Numero Consultorio:</span>
            <span className='cotizacion-item__info-valor'>{doc.telconsultorio}</span>
          </p>
        </div>
            
        <div className='column'>
          <p className='cotizacion-item__info'>
            <label className='estatus_gn'>Estatus General: <Label className={`cotizacion-item__info-statusinde`} color={getStatusGeneralColor}>
              {doc.estatusgeneral}
            </Label></label>
            <label className='estatus_GC'>Estatus de Asesor: <Label className={`cotizacion-item__info-statusgcome`} color={colorstatusasesor}>
              {doc.estatusgeneral === 'Cancelado' ? 'Cancelado' : (doc.checkA ? 'Finalizado' : 'Pendiente')}
            </Label></label>
            <label className='estatus_GC'>Estatus de Gestión Comercial: <Label className={`cotizacion-item__info-statusgcome`} color={colorstatuscome}>
              {doc.estatusgeneral === 'Cancelado' ? 'Cancelado' : (doc.checkC ? 'Finalizado' : 'Pendiente')}
            </Label></label>
          </p>
        </div>
        
        <div style={{ display: 'flex', gap: '0' }}>
          {doc.estatusgeneral !== 'Cancelado' ? (!doc.checkA && (
            <Button icon color='teal' onClick={CheckDoctor}>
              <Icon name='check'/>
            </Button>
          )) : ''}
          {doc.estatusgeneral !== 'Cancelado' ? (!doc.checkC && (
          <Button icon primary onClick={openFormDoc}>
            <Icon name='edit'/>
          </Button>
            )) : ''}
          <Button icon primary onClick={openVerDoc}>
            <Icon name='eye' />
          </Button>
          {doc.estatusgeneral !== 'Cancelado' ? (!doc.checkC && (
            <Button icon color='red' onClick={openDesactivateActivateConfirm}>
              <Icon name='trash alternate outline' />
            </Button>
          )) : ''}
        </div>
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        {contentView}
      </BasicModal>
      <BasicModal show={showModal2} close={onOpenCloseModal2} title={titleModal}>
        {contentForm}
      </BasicModal>
      <Confirm open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={CancelCliente} content={confirmMessage} size='mini' />
    </>
  )
}
