import React, { useCallback, useState } from 'react';
import { Header, Icon, Label } from 'semantic-ui-react';
import { Form, Container } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone';
import { initialValuesalta, validationSchemaalta } from './AltaClienteForm.form'
import { useAuth } from '../../../../hooks'
import "./AltaClienteForm.scss";
import { Doctor } from '../../../../api';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import { es } from 'date-fns/locale/es';
registerLocale('es', es)

const especialidades = [
    { key: "ANESTESIOLOGIA", text: "ANESTESIOLOGIA", value: "ANESTESIOLOGIA" },
    { key: "ANGEOLOG", text: "ANGEOLOG", value: "ANGEOLOG" },
    { key: "BARIATRA", text: "BARIATRA", value: "BARIATRA" },
    { key: "CARDIOLOGO", text: "CARDIOLOGO", value: "CARDIOLOGO" },
    { key: "CIRUJANOPLASTICO", text: "CIRUJANO PLASTICO", value: "CIRUJANOPLASTICO" },
    { key: "CLINICASPA", text: "CLINICA/SPA", value: "CLINICA/SPA" },
    { key: "DERMACET", text: "DERMA CET", value: "DERMACET" },
    { key: "ENDOCRINOLOGO", text: "ENDOCRINOLOGO", value: "ENDOCRINOLOGO" },
    { key: "EPIDEMIOLOGO", text: "EPIDEMIOLOGO", value: "EPIDEMIOLOGO" },
    { key: "ESTETICAYLONGEVIDAD", text: "ESTETICA Y LONGEVIDAD", value: "ESTETICAYLONGEVIDAD" },
    { key: "ESTOMATOLOGO", text: "ESTOMATOLOGO", value: "ESTOMATOLOGO" },
    { key: "GASTROENTEROLOGO", text: "GASTROENTEROLOGO", value: "GASTROENTEROLOGO" },
    { key: "GERIATRIA", text: "GERIATRIA", value: "GERIATRIA" },
    { key: "GINECOLOGO", text: "GINECOLOGO", value: "GINECOLOGO" },
    { key: "HOMEOPATA", text: "HOMEOPATA", value: "HOMEOPATA" },
    { key: "MEDICINADELDEPORTE", text: "MEDICINA DEL DEPORTE", value: "MEDICINADELDEPORTE" },
    { key: "MEDICINAINTERNA", text: "MEDICINA INTERNA", value: "MEDICINAINTERNA" },
    { key: "MEDICODERMATOLOGO", text: "MEDICO DERMATOLOGO", value: "MEDICODERMATOLOGO" },
    { key: "MEDICOGENERAL", text: "MEDICO GENERAL", value: "MEDICOGENERAL" },
    { key: "MEDICO UROLOGO", text: "MEDICO UROLOGO", value: "MEDICOUROLOGO" },
    { key: "MEDICO VETERINARIO", text: "MEDICO VETERINARIO", value: "MEDICOVETERINARIO" },
    { key: "OFTALMOLOGO", text: "OFTALMOLOGO", value: "OFTALMOLOGO" },
    { key: "OTORINOLARINGOLOGO", text: "OTORINOLARINGOLOGO", value: "OTORINOLARINGOLOGO" },
    { key: "PEDIATRA", text: "PEDIATRA", value: "PEDIATRA" },
    { key: "PODOLOGO", text: "PODOLOGO", value: "PODOLOGO" }
];

const DoctorControllador = new Doctor();


export function AltaClienteForm(props) {
    const { onClose, onReload, doc} = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;
    const [myFiles, setMyFiles] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const formik = useFormik({
        initialValues: initialValuesalta(CorreoAsesor, nombreCompleto),
        validationSchema: validationSchemaalta(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
           console.log(formValue);
            try {
                if (myFiles === null || myFiles.length === 0) {
                    alert("Porfavor ingresa al menos un archivo");
                    
                }else if(formValue.factura === false && formValue.domicilio === false){
                    alert("Porfavor ingresa al menos una dirección de entrega (dirección de la factura o domicilio)");
                }else{
                    const data = {
                        nombre: formValue.nombre,
                        fecnacimiento: startDate,
                        cedulamed: formValue.cedulamed,
                        asesor: formValue.asesor,
                        asesornom: formValue.asesornom,
                        email: formValue.email,
                        emailalternativo: formValue.emailalternativo,
                        telconsultorio: formValue.telconsultorio,
                        telpersonal: formValue.telpersonal,
                        especialidad: formValue.especialidad,
                        nombreespecialidad: formValue.nombreespecialidad,
                        cedulaesp: formValue.cedulaesp,
                        factura: formValue.factura,
                        razonsocial: formValue.razonsocial,
                        rfc: formValue.rfc,
                        callef: formValue.callef,
                        numeroextf: formValue.numeroextf,
                        numerointf: formValue.numerointf,
                        coloniaf: formValue.coloniaf,
                        municipiof: formValue.municipiof,
                        estadof: formValue.estadof,
                        cpf: formValue.cpf,
                        atencionf: formValue.atencionf,
                        domicilio: formValue.domicilio,
                        calle: formValue.calle,
                        numeroext: formValue.numeroext,
                        numeroint: formValue.numeroint,
                        colonia: formValue.colonia,
                        municipio: formValue.municipio,
                        estado: formValue.estado,
                        cp: formValue.cp,
                        atencion: formValue.atencion,
                        checkA : false,
                        file : myFiles
                    };
                 
                    if (!doc) {
                        try {
                            await DoctorControllador.createDoctor(accessToken, data);
                        } catch (error) {
                            if (error.status === 400) {  // Verifica si es un error 400
                                alert('Ocurrió un error. Por favor, verifica tu conexión a internet y vuelve a intentar la subida de archivos.');
                                onClose();
                                onReload();
                            } else {
                                console.log('Error inesperado:', error);
                            }
                        }
                    } else {
                        console.log('Actualizar Ticket, no debería de pasar');
                    }
                    onClose();
                    onReload();
                    
                }
                
            } catch (error) {
                console.error(error);
            }
        }
    });

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const fileUrl = URL.createObjectURL(file);
        formik.setFieldValue("documentos", fileUrl);
        formik.setFieldValue("fileDocumentos", file);
        setMyFiles([...myFiles, ...acceptedFiles])
        console.log(myFiles);
    }, [myFiles, formik]);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'image/png': ['.jpeg', '.png', '.jpg'],
            'application/pdf': ['.doc', '.docx', '.pdf', '.xls', '.xlsx']
        },
        onDrop,
    });

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const files = myFiles.map(file => (
        <span key={file.path}>
            {file.path}
            <Icon onClick={removeFile(file)} name='trash alternate' />
        </span>
    ))


    return (
        <Form autoComplete="off" onSubmit={formik.handleSubmit} className='form'>
            <Container className='form-cotizacion__primer'>
                <Form.Input label="Nombre" name="nombre" placeholder="Nombre del Cliente" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("nombre", uppercasedValue); }} value={formik.values.nombre} error={formik.errors.nombre} />
                <Form.Field>
                <label>Fecha de Nacimiento</label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                />
                </Form.Field>
                <Form.Input label="Cédula Médica" name="cedulamed" placeholder="Cédula Medica" onChange={(e) => {if (e.target.value.length <= 8) {formik.setFieldValue('cedulamed', e.target.value.replace(/[^0-9]/g, ''))}}} value={formik.values.cedulamed} error={formik.errors.cedulamed} />
                <Form.Input label="Correo electrónico" name="email" placeholder="Correo electrónico" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase(); formik.setFieldValue("email", uppercasedValue); }}  value={formik.values.email} error={formik.errors.email} />
                <Form.Input label="Correo electrónico alternativo" name="emailalternativo" placeholder="Correo alternativo" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase(); formik.setFieldValue("emailalternativo", uppercasedValue); }}  value={formik.values.emailalternativo} error={formik.errors.emailalternativo} />
                <Form.Input label="Teléfono de Consultorio" name="telconsultorio" placeholder="Teléfono del consultorio" onChange={(e) => {if (e.target.value.length <= 10) {formik.setFieldValue('telconsultorio', e.target.value.replace(/[^0-9]/g, ''))}}} value={formik.values.telconsultorio} error={formik.errors.telconsultorio} />
                <Form.Input label="Teléfono personal" name="telpersonal" placeholder="Celular Personal" onChange={(e) => {if (e.target.value.length <= 10) {formik.setFieldValue('telpersonal', e.target.value.replace(/[^0-9]/g, ''))}}} value={formik.values.telpersonal} error={formik.errors.telpersonal} />
            </Container>
            <Container className='form-cotizacion__tercero'>
                <br />
                <Form.Checkbox label='Especialidad' name='especialidad' onChange={(_, data) => formik.setFieldValue("especialidad", data.checked)} checked={formik.values.especialidad} error={formik.errors.especialidad} />
                <br />
            </Container>
            {
            formik.values.especialidad && (
            <Container>
                <Form.Dropdown label="Nombre especialidad" placeholder="Seleccione una especialidad" name="nombreespecialidad" options={especialidades} selection onChange={(_, data) => formik.setFieldValue("nombreespecialidad", data.value)} value={formik.values.nombreespecialidad} error={formik.errors.nombreespecialidad} />
                <Form.Input label="Cédula especialidad" name="cedulaesp" placeholder="Cedula de especialidad" onChange={(e) => {if (e.target.value.length <= 8) {formik.setFieldValue('cedulaesp', e.target.value.replace(/[^0-9]/g, ''))}}}  value={formik.values.cedulaesp} error={formik.errors.cedulaesp} />
            </Container>
            )}
             <Container className='form-cotizacion__tercero'>
                <br />
                <Form.Checkbox label='Facturar' name='factura' onChange={(_, data) => formik.setFieldValue("factura", data.checked)} checked={formik.values.factura} error={formik.errors.factura} />
                <br />
            </Container>
            {
            formik.values.factura && (
            <Container>
                <Form.Input label="Razón Social" name="razonsocial" placeholder="Razón Social" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("razonsocial", uppercasedValue); }}  value={formik.values.razonsocial} error={formik.errors.razonsocial} />
                <Form.Input label="RFC" name="rfc" placeholder="RFC" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toUpperCase(); formik.setFieldValue("rfc", uppercasedValue); }}  value={formik.values.rfc} error={formik.errors.rfc} />
                <Form.Input label="Calle F" name="callef" placeholder="Calle" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z0-9 ]/g, "").toUpperCase(); formik.setFieldValue("callef", uppercasedValue); }}  value={formik.values.callef} error={formik.errors.callef} />
                <Form.Input label="Número Exterior F" name="numeroextf" placeholder="Número exterior" onChange={formik.handleChange}  value={formik.values.numeroextf} error={formik.errors.numeroextf} />
                <Form.Input label="Número Interior F" name="numerointf" placeholder="Número interior" onChange={formik.handleChange}  value={formik.values.numerointf} error={formik.errors.numerointf} />
                <Form.Input label="Colonia F" name="coloniaf" placeholder="Colonia" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z0-9 ]/g, "").toUpperCase(); formik.setFieldValue("coloniaf", uppercasedValue); }}  value={formik.values.coloniaf} error={formik.errors.coloniaf} />
                <Form.Input label="Municipio F" name="municipiof" placeholder="Municipio" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("municipiof", uppercasedValue); }}  value={formik.values.municipiof} error={formik.errors.municipiof} />
                <Form.Input label="Estado F" name="estadof" placeholder="Estado" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("estadof", uppercasedValue); }}  value={formik.values.estadof} error={formik.errors.estadof} />
                <Form.Input label="Código Postal F" name="cpf" placeholder="Codigo Postal" onChange={(e) => {const uppercasedValue = e.target.value.toUpperCase(); formik.setFieldValue("cpf", uppercasedValue); }}  value={formik.values.cpf} error={formik.errors.cpf} />
                <Form.Input label="En atención a F" name="atencionf" placeholder="En atención a" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("atencionf", uppercasedValue); }}  value={formik.values.atencionf} error={formik.errors.atencionf} />
            </Container>
            )}
            <Container className='form-cotizacion__tercero'>
                <br />
                <Form.Checkbox label='Domicilio de entrega' name='domicilio' onChange={(_, data) => formik.setFieldValue("domicilio", data.checked)} checked={formik.values.domicilio} error={formik.errors.domicilio} />
                <br />
            </Container>
            {
            formik.values.domicilio && (
            <Container>
                <Form.Input label="Calle" name="calle" placeholder="Calle" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z0-9 ]/g, "").toUpperCase(); formik.setFieldValue("calle", uppercasedValue); }}  value={formik.values.calle} error={formik.errors.calle} />
                <Form.Input label="Número Exterior" name="numeroext" placeholder="Número exterior" onChange={formik.handleChange}  value={formik.values.numeroext} error={formik.errors.numeroext} />
                <Form.Input label="Número Interior" name="numeroint" placeholder="Número interior" onChange={formik.handleChange}  value={formik.values.numeroint} error={formik.errors.numeroint} />
                <Form.Input label="Colonia" name="colonia" placeholder="Colonia" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z0-9 ]/g, "").toUpperCase(); formik.setFieldValue("colonia", uppercasedValue); }}  value={formik.values.colonia} error={formik.errors.colonia} />
                <Form.Input label="Municipio" name="municipio" placeholder="Municipio" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("municipio", uppercasedValue); }}  value={formik.values.municipio} error={formik.errors.municipio} />
                <Form.Input label="Estado" name="estado" placeholder="Estado" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("estado", uppercasedValue); }}  value={formik.values.estado} error={formik.errors.estado} />
                <Form.Input label="Código Postal" name="cp" placeholder="Codigo Postal" onChange={(e) => {const uppercasedValue = e.target.value.toUpperCase(); formik.setFieldValue("cp", uppercasedValue); }}  value={formik.values.cp} error={formik.errors.cp} />
                <Form.Input label="En atención a" name="atencion" placeholder="En atención a" onChange={(e) => {const uppercasedValue = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").replace(/[^A-ZÀ-ÿa-z ]/g, "").toUpperCase(); formik.setFieldValue("atencion", uppercasedValue); }}  value={formik.values.atencion} error={formik.errors.atencion} />
            </Container>
            )}
            <h4>Agregar Archivos</h4>
            <div className='soporte-form__documentos' {...getRootProps()}>
                <Label>
                    <Header icon>
                        <Icon name='file text outline' />
                        
                    </Header>
                    <input {...getInputProps()} name="documentos" />
                    {isDragAccept && (<p>Imágenes (jpg, png, jpeg), Archivos (.doc, .docx, .pdf, .xls, .xlsx)</p>)}
                    {isDragReject && (<p>Otros tipos de Archivos son Rechazados</p>)}
                    {isDragActive && (<p>Suelta varios Archivos Aquí...</p>)}
                </Label>
            </div>
            {files}
            <br/>

            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
        </Form>
    )
}
