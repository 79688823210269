import * as Yup from "yup";

export function initialValues(elite) {
    return {
        cliente: elite?.cliente || "",
        asesor: elite?.asesor || "",
        asesornom: elite?.asesornom || "",
        padecimiento: elite?.padecimiento || "",
        base: elite?.base || "",
        activos: elite?.activos || "",
        especialidad: elite?.especialidad || "",
        cardcode: elite?.cardcode || "",
        precio30: elite?.precio30 || "",
        precio50: elite?.precio50 || "",
        precio120: elite?.precio120 || "",
        precio240: elite?.precio240 || "",
        precio1L: elite?.precio1L || "",
        precio30caps: elite?.precio30caps || "",
        precio60caps: elite?.precio60caps || "",
        caducidad: elite?.caducidad || "",
        observaciones: elite?.observaciones || "",
    }
}

export function validationSchema() {
    return Yup.object({
        caducidad: Yup.string().required(true),
        base: Yup.string(),

        activos: Yup.string().required(true),
    })
}
