import { Header } from 'semantic-ui-react';
import { Form, Container } from 'semantic-ui-react'
import { useFormik } from 'formik'

import { initialValuesaltacome, validationSchemaaltacome } from './AltaClienteComeForm.form'
import { useAuth } from '../../../../hooks'
import "./AltaClienteComeForm.scss";
import { Doctor } from '../../../../api';


const DoctorControllador = new Doctor();

export function AltaClienteComeForm(props) {
    const { onClose, onReload, doc} = props;
    const { accessToken } = useAuth();
    const formik = useFormik({
        initialValues: initialValuesaltacome(doc),
        validationSchema: validationSchemaaltacome(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
           console.log(formValue);
            try {
                    const catalogos = [formValue.dermazone, formValue.reve, formValue.bioelite, formValue.bioequal, formValue.omigenomics];
                    const data = {
                        catalogos:  catalogos,
                        cardcode: formValue.cardcode,
                    }
                 
                    if (doc) {
                        await DoctorControllador.UpdateCatalogDoctor(accessToken, doc._id ,data);
                        console.log("pase del reload")
                    } else {
                        console.log('no existe doc esto no deberia de pasar');
                    }
            } catch (error) {
                alert("Algo falló, intentelo de nuevo.")
                onClose();  // Se ejecuta siempre
                onReload(); // Se ejecuta siempre
            } finally {
                console.log("llegue al finally")
                onClose();  // Se ejecuta siempre
                onReload(); // Se ejecuta siempre
            }
        }
    });

    return (
    <Form autoComplete="off" onSubmit={formik.handleSubmit} className='form'>
        <Container className='cotizacion-principal_info'>
        {doc.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{doc.folio}</span></p>}
        {doc.nombre && <p>Nombre: <span className='cotizacion-principal_info-nombre'>{doc.nombre}</span></p>}
        {doc.fecnacimiento && <p>Fecha de Nacimiento: <span className='cotizacion-principal_info-fecnacimiento'>{new Date(doc.fecnacimiento).toLocaleDateString()}</span></p>}
        {doc.cedulamed && <p>Cédula Médica: <span className='cotizacion-principal_info-cedulamed'>{doc.cedulamed}</span></p>}
        {doc.email && <p>Email: <span className='cotizacion-principal_info-email'>{doc.email}</span></p>}
        {doc.asesor && <p>Asesor: <span className='cotizacion-principal_info-asesor'>{doc.asesor}</span></p>}
        {doc.asesornom && <p>Nombre del Asesor: <span className='cotizacion-principal_info-asesornom'>{doc.asesornom}</span></p>}
        {doc.emailalternativo && <p>Email Alternativo: <span className='cotizacion-principal_info-emailalternativo'>{doc.emailalternativo}</span></p>}
        {doc.telconsultorio && <p>Teléfono Consultorio: <span className='cotizacion-principal_info-telconsultorio'>{doc.telconsultorio}</span></p>}
        {doc.telpersonal && <p>Teléfono Personal: <span className='cotizacion-principal_info-telpersonal'>{doc.telpersonal}</span></p>}
        {doc.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(doc.created_at).toLocaleDateString()}</span></p>}
        </Container>
            
        <Container className='form-cotizacion__primer'>
            <Header className='center'>Catálogos</Header>
            
                <Form.Checkbox label='DERMAZONE'
                name='derma' onChange={(_, data) => formik.setFieldValue("dermazone", data.checked)} readOnly checked={formik.values.dermazone} error={formik.errors.dermazone} className='custom-checkbox' />
                <Form.Checkbox label='REVE'
                name='reve' onChange={(_, data) => formik.setFieldValue("reve", data.checked)} readOnly checked={formik.values.reve} error={formik.errors.reve} className='custom-checkbox' />
               <Form.Checkbox label='BIOELITE'
                name='bioelite' onChange={(_, data) => formik.setFieldValue("bioelite", data.checked)} readOnly checked={formik.values.bioelite} error={formik.errors.bioelite} className='custom-checkbox' />
                <Form.Checkbox label='BIOEQUAL'
                name='bioequal' onChange={(_, data) => formik.setFieldValue("bioequal", data.checked)} checked={formik.values.bioequal} error={formik.errors.bioequal} className='custom-checkbox' />
                <Form.Checkbox label='OMIGENOMICS'
                name='omi' onChange={(_, data) => formik.setFieldValue("omigenomics", data.checked)} checked={formik.values.omigenomics} error={formik.errors.omigenomics} className='custom-checkbox' />
            
        </Container>
       <Container>
         <Form.Input label="Cardcode" name="cardcode" placeholder="Cardcode" onChange={formik.handleChange}  value={formik.values.cardcode} error={formik.errors.cardcode} />
       </Container>
        <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
            Aceptar
        </Form.Button>
    </Form>
    )
}
