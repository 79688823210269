import * as Yup from "yup";

export function initialValues(equal, email, nombreCompleto) {
    return {
        cliente: equal?.cliente || "",
        asesor: equal && equal.asesor ? equal.asesor : email,
        asesornom: equal && equal.asesornom ? equal.asesornom : nombreCompleto,
        padecimiento: equal?.padecimiento || "",
        base: equal?.base || "",
        viauso: equal?.viauso || "",
        activos: equal?.activos || "",
        modo: equal?.modo || "",
        especialidad: equal?.especialidad || "",
        cardcode: equal?.cardcode || ""
    }
}

export function validationSchema(cardcodes) {
    const cardcode = cardcodes.map(cardcode => cardcode.cardcode);

    return Yup.object({
        cliente: Yup.string().required(true),
        base: Yup.string().required(true),
        viauso: Yup.string().required(true),
        genero: Yup.string().required(true),
        padecimiento: Yup.string().required(true),
        activos: Yup.string().required(true),
        modo: Yup.string().required(true),

        especialidad: Yup.string().required(true),
        restringida: Yup.boolean(), // Aseguramos que el campo 'restringida' esté presente
        cardcode: Yup.string().when(['especialidad', 'restringida'], {
            is: (especialidad, restringida) =>
                ["ENDOCRINOLOGO", "GINECOLOGO", "MEDICOUROLOGO"].includes(especialidad) && restringida === true,
          then: () => Yup.mixed()
          .oneOf(cardcode, 'El cardcode ingresado no es válido, carga la firma por primera vez') 
          .required('Este campo es obligatorio'),
          otherwise: () => Yup.string().required(true)
        }),

    })  
}