import * as Yup from "yup";

export function initialValues(elite, email, nombreCompleto) {
    return {
        cliente: elite?.cliente || "",
        asesor: elite && elite.asesor ? elite.asesor : email,
        asesornom: elite && elite.asesornom ? elite.asesornom : nombreCompleto,
        padecimiento: elite?.padecimiento || "",
        base: elite?.base || "",
        activos: elite?.activos || "",
        especialidad: elite?.especialidad || "",
        cardcode: elite?.cardcode || ""
    }
}

export function validationSchema() {
    return Yup.object({
        cliente: Yup.string().required(true),
        base: Yup.string().required(true),
        padecimiento: Yup.string().required(true),
        cardcode: Yup.string().required(true),
        especialidad: Yup.string().required(true),
        activos: Yup.string().required(true)
    })  
}