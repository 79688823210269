import { ENV } from "../utils";

export class Bioequal {
    baseApi = ENV.BASE_API;
    
    
    async createBioequal(accessToken, BioequalData) {
      try {
          const data = BioequalData;
          const url = `${this.baseApi}/${ENV.API_ROUTES.BIOEQUAL}`
          const params = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(data),
          }
          const response = await fetch(url, params)
          const result = await response.json()
          if (response.status !== 200) throw result;
          return result;
      } catch (error) {
          throw error;
      }
  }

  
  async updateBioequal(accessToken, idBioequal, BioequalData) {
    try {
        const data = BioequalData;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const url = `${this.baseApi}/${ENV.API_ROUTES.BIOEQUAL}/${idBioequal}`
        const params = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        }
        const response = await fetch(url, params)
        const result = await response.json()
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}
   
async getBioequalsByAsesor(asesor, params) {
    try {
        const pageFilter = `page=${params?.page || 1}`;
        const limitFilter = `limit=${params?.limit || 10}`;
        const email = `asesor=${asesor || ""}`;
        const url = `${this.baseApi}/${ENV.API_ROUTES.BIOEQUALS}?${email}&${pageFilter}&${limitFilter}`;
        const response = await fetch(url);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error
    }
  }

  async getBioequals(params) {
    try {
        const pageFilter = `page=${params?.page || 1}`;
        const limitFilter = `limit=${params?.limit || 10}`;
        const url = `${this.baseApi}/${ENV.API_ROUTES.BIOEQUALS}?${pageFilter}&${limitFilter}`;
        const response = await fetch(url);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error
    }
  }
    
  async UpdateInyde(accessToken, id, BioequalData) {
    try {
        const data = BioequalData;
        console.log(data);
        const url = `${this.baseApi}/${ENV.API_ROUTES.INYDE}/${ENV.API_ROUTES.BIOEQUAL}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 201) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async CancelBioequal(accessToken, id, reason) {
    const data = {
        motivo: reason
    }
    console.log("esta data es: " + reason)
    try {
        const url = `${this.baseApi}/${ENV.API_ROUTES.INYDE}/${ENV.API_ROUTES.BIOEQUAL}/${id}`
        const params = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 201) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async CancelBioequalAsesor(accessToken, id) {

    try {
        const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.BIOEQUAL}/${id}`
        const params = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 201) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async UpdateOpe(accessToken, id, bioequalData) {
    try {
        console.log("entre al ope")
        const data = bioequalData;
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
              formData.append(key, data[key]);
          })
        const url = `${this.baseApi}/${ENV.API_ROUTES.OPE}/${ENV.API_ROUTES.BIOEQUAL}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async UpdateOpe2(accessToken, id, bioequalData) {
    try {
        console.log("entre al ope")
        const data = bioequalData;
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
              formData.append(key, data[key]);
          })
        const url = `${this.baseApi}/${ENV.API_ROUTES.OPE}/${ENV.API_ROUTES.BIOEQUALFINAL}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async saveBioequalOpe(accessToken, id, bioequalData) {
    try {
        const data = bioequalData;
        console.log(bioequalData)
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
              formData.append(key, data[key]);
          })
        const url = `${this.baseApi}/${ENV.API_ROUTES.SAVEOPE}/${ENV.API_ROUTES.BIOEQUAL}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async UpdateAsesorCotizacion(accessToken, id, data) {
    try {
        const formData = new FormData();
        if (data.file && data.file.length > 0) {
            Array.from(data.file).forEach((file) => {
              formData.append('documentos', file);  // Asegúrate de que es un objeto File
            });
          }
        
          // Adjuntar otros datos
          Object.keys(data).forEach((key) => {
            if (key !== 'file') {
              formData.append(key, data[key]);
            }
          });
        const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.BIOEQUAL}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async CreateFirmaBioequal(accessToken, data){
    console.log(data);
        try {
            const formData = new FormData();
            if (data.file && data.file.length > 0) {
                Array.from(data.file).forEach((file) => {
                  formData.append('documentos', file);  // Asegúrate de que es un objeto File
                });
              }
            
              // Adjuntar otros datos
              Object.keys(data).forEach((key) => {
                if (key !== 'file') {
                  formData.append(key, data[key]);
                }
              });
            const url = `${this.baseApi}/${ENV.API_ROUTES.FIRMABIOEQUAL}`
            const params = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: formData,
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 201) throw result;
            return result;
        } catch (error) {
            throw error;
        }
}

async GetFirmaBioequal(params){
    try {
        const pageFilter = `page=${params?.page || 1}`;
        const limitFilter = `limit=${params?.limit || 10}`;
        const url = `${this.baseApi}/${ENV.API_ROUTES.FIRMABIOEQUAL}?${pageFilter}&${limitFilter}`;
        const response = await fetch(url);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error
    }
}

async getAvailableCardcodes(){
    try {
        const url = `${this.baseApi}/${ENV.API_ROUTES.FIRMABIOEQUAL}`;
        const response = await fetch(url);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error
    }
}


}