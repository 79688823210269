import React from 'react'
import { Container, Form, Header } from 'semantic-ui-react';
import {ENV} from './../../../../utils/constants'

export function AltaClienteComeView(props) {
  const { doc } = props;

  return (
    <div className='cotizacion-principal'>
      <Container className='cotizacion-principal_info'>
        {doc.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{doc.folio}</span></p>}
        {doc.nombre && <p>Nombre: <span className='cotizacion-principal_info-nombre'>{doc.nombre}</span></p>}
        {doc.fecnacimiento && <p>Fecha de Nacimiento: <span className='cotizacion-principal_info-fecnacimiento'>{new Date(doc.fecnacimiento).toLocaleDateString()}</span></p>}
        {doc.cedulamed && <p>Cédula Médica: <span className='cotizacion-principal_info-cedulamed'>{doc.cedulamed}</span></p>}
        {doc.email && <p>Email: <span className='cotizacion-principal_info-email'>{doc.email}</span></p>}
        {doc.asesor && <p>Asesor: <span className='cotizacion-principal_info-asesor'>{doc.asesor}</span></p>}
        {doc.asesornom && <p>Nombre del Asesor: <span className='cotizacion-principal_info-asesornom'>{doc.asesornom}</span></p>}
        {doc.emailalternativo && <p>Email Alternativo: <span className='cotizacion-principal_info-emailalternativo'>{doc.emailalternativo}</span></p>}
        {doc.telconsultorio && <p>Teléfono Consultorio: <span className='cotizacion-principal_info-telconsultorio'>{doc.telconsultorio}</span></p>}
        {doc.telpersonal && <p>Teléfono Personal: <span className='cotizacion-principal_info-telpersonal'>{doc.telpersonal}</span></p>}
        
        {doc.especialidad && (
          <>
          <br/>
          {doc.especialidad && <p>Especialidad: <span className='cotizacion-principal_info-factura'>{doc.especialidad ? 'Sí' : 'No'}</span></p>}
          {doc.nombreespecialidad && <p>Nombre de especialidad: <span className='cotizacion-principal_info-especialidad'>{doc.nombreespecialidad}</span></p>}
          {doc.cedulaesp && <p>Cédula de Especialidad: <span className='cotizacion-principal_info-cedulaesp'>{doc.cedulaesp}</span></p>}
          </>
        )}

        {doc.factura && (
          <>
            <br/>
            {doc.factura && <p>Factura: <span className='cotizacion-principal_info-factura'>{doc.factura ? 'Sí' : 'No'}</span></p>}
            {doc.razonsocial && <p>Razón Social: <span className='cotizacion-principal_info-razonsocial'>{doc.razonsocial}</span></p>}
            {doc.rfc && <p>RFC: <span className='cotizacion-principal_info-rfc'>{doc.rfc}</span></p>}
            {doc.callef && <p>Calle Fiscal: <span className='cotizacion-principal_info-callef'>{doc.callef}</span></p>}
            {doc.numeroextf && <p>Número Exterior Fiscal: <span className='cotizacion-principal_info-numeroextf'>{doc.numeroextf}</span></p>}
            {doc.numerointf && <p>Número Interior Fiscal: <span className='cotizacion-principal_info-numerointf'>{doc.numerointf}</span></p>}
            {doc.coloniaf && <p>Colonia Fiscal: <span className='cotizacion-principal_info-coloniaf'>{doc.coloniaf}</span></p>}
            {doc.municipiof && <p>Municipio Fiscal: <span className='cotizacion-principal_info-municipiof'>{doc.municipiof}</span></p>}
            {doc.estadof && <p>Estado Fiscal: <span className='cotizacion-principal_info-estadof'>{doc.estadof}</span></p>}
            {doc.cpf && <p>CP Fiscal: <span className='cotizacion-principal_info-cpf'>{doc.cpf}</span></p>}
            {doc.atencionf && <p>Atención Fiscal: <span className='cotizacion-principal_info-atencionf'>{doc.atencionf}</span></p>}
          </>
        )}

        {doc.domicilio && (
          <>
            <br/>
            {doc.domicilio && <p>Domicilio: <span className='cotizacion-principal_info-domicilio'>{doc.domicilio ? 'Sí' : 'No'}</span></p>}
            {doc.calle && <p>Calle: <span className='cotizacion-principal_info-calle'>{doc.calle}</span></p>}
            {doc.numeroext && <p>Número Exterior: <span className='cotizacion-principal_info-numeroext'>{doc.numeroext}</span></p>}
            {doc.numeroint && <p>Número Interior: <span className='cotizacion-principal_info-numeroint'>{doc.numeroint}</span></p>}
            {doc.colonia && <p>Colonia: <span className='cotizacion-principal_info-colonia'>{doc.colonia}</span></p>}
            {doc.municipio && <p>Municipio: <span className='cotizacion-principal_info-municipio'>{doc.municipio}</span></p>}
            {doc.estado && <p>Estado: <span className='cotizacion-principal_info-estado'>{doc.estado}</span></p>}
            {doc.cp && <p>CP: <span className='cotizacion-principal_info-cp'>{doc.cp}</span></p>}
            {doc.atencion && <p>Atención: <span className='cotizacion-principal_info-atencion'>{doc.atencion}</span></p>}
          </>
        )}
        {doc.files?.length > 0 && (
                doc.files.map((file, index) => (
                  <a href={ENV.DOCTORDOCS+"/"+file} download={ENV.DOCTORDOCS+file} ><p>Archivo: <span className='cotizacion-principal_info-created_at'>{ENV.DOCTORDOCS}/{file}</span></p></a>
                ))
        )}  
        <br/>
        <Header className='center'>Catalogos</Header>

        <Form.Checkbox label='DERMAZONE' name='dermazone' readOnly checked={doc.catalogos[0]} className='custom-checkbox' />
        <Form.Checkbox label='REVE' name='reve' readOnly checked={doc.catalogos[1]} className='custom-checkbox' />
        <Form.Checkbox label='BIOELITE' name='bioelite' readOnly checked={doc.catalogos[2]} className='custom-checkbox' />
        <Form.Checkbox label='BIOEQUAL' name='bioequal' readOnly checked={doc.catalogos[3]} className='custom-checkbox' />
        <Form.Checkbox label='OMIGENOMICS' name='omigenomics' readOnly checked={doc.catalogos[4]} className='custom-checkbox' />
        
      <br/>
        {doc.cardcode && <p>Cardcode: <span className='cotizacion-principal_info-atencion'>{doc.cardcode}</span></p>}
        {doc.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(doc.created_at).toLocaleDateString()}</span></p>}

      </Container>
    </div>

  )
}
