import React from 'react'
import { Container, TableRow, TableBody, TableHeader, TableHeaderCell, Table, TableCell} from 'semantic-ui-react';
import {ENV} from './../../../../../utils/constants'

export function OpeView(props) {
  const { bioequal } = props;

  return (
    <div className='cotizacion-principal'>
      <Container className='cotizacion-principal_info'>
      <Container className='cotizacion-principal_info'>
                {bioequal.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{bioequal.folio}</span></p>}
                {bioequal.asesor && <p>Correo Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesor}</span></p>}
                {bioequal.asesornom && <p>Nombre de Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesornom}</span></p>}
                {bioequal.cardcode && <p>Cardcode de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cardcode}</span></p>}
                {bioequal.cliente && <p>Nombre de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cliente}</span></p>}
                {bioequal.especialidad && <p>Especialidad: <span className='cotizacion-principal_info-nombre'>{bioequal.especialidad}</span></p>}
                {bioequal.base && <p>Base: <span className='cotizacion-principal_info-nombre'>{bioequal.base}</span></p>}
                {bioequal.viauso && <p>Vía de administración: <span className='cotizacion-principal_info-nombre'>{bioequal.viauso}</span></p>}
                {bioequal.activos && <p>Activos: <span className='cotizacion-principal_info-nombre'>{bioequal.activos}</span></p>}
                {bioequal.padecimiento && <p>Padecimiento: <span className='cotizacion-principal_info-nombre'>{bioequal.padecimiento}</span></p>}
                {bioequal.modo && <p>Modo de uso: <span className='cotizacion-principal_info-nombre'>{bioequal.modo}</span></p>}
                <p>Restringida: <span className='cotizacion-principal_info-nombre'>{bioequal.restringida ? 'Si' : 'No'}</span></p>
                {bioequal.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(bioequal.created_at).toLocaleDateString()}</span></p>}
                {bioequal.codigo ? <p>Codigo Formula: <span className='cotizacion-principal_info-domicilio'> {bioequal.codigo}</span></p> : ""}
                {bioequal.motivo && <p>Motivo de cancelación: <span className='cotizacion-principal_info-nombre'>{bioequal.motivo}</span></p>}
                {bioequal.files?.length > 0 && (
                bioequal.files.map((file, index) => (
                  <a href={ENV.DOCTORDOCS+"/"+file} download={ENV.DOCTORDOCS+file} ><p>Archivo: <span className='cotizacion-principal_info-created_at'>{ENV.DOCTORDOCS}/{file}</span></p></a>
                ))
        )}
            </Container>
        <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell>Presentaciones</TableHeaderCell>
                        <TableHeaderCell>Precio</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                        <TableCell>Precio Unitario</TableCell>
                        <TableCell>{bioequal.precioUni}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Container>
      </Container>
      
    </div>

  )
}
