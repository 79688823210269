import * as Yup from "yup";

export function initialValuesalta(email, nombreCompleto, doc) {
    return {
        folio: doc?.folio || 0,
        nombre: doc?.nombre || "",
        fecnacimiento: doc?.fecnacimiento || "",
        cedulamed: doc?.cedulamed || "",
        asesor: doc?.asesor || email,
        asesornom: doc?.asesornom || nombreCompleto,
        email: doc?.email || "",
        emailalternativo: doc?.emailalternativo || "",
        telconsultorio: doc?.telconsultorio || "",
        telpersonal: doc?.telpersonal || "",
        especialidad: doc?.especialidad || false,
        nombreespecialidad: doc?.nombreespecialidad || "",
        cedulaesp: doc?.cedulaesp || "",
        factura: doc?.factura || false,
        razonsocial: doc?.razonsocial || "",
        rfc: doc?.rfc || "",
        callef: doc?.callef || "",
        numeroextf: doc?.numeroextf || "",
        numerointf: doc?.numerointf || "",
        coloniaf: doc?.coloniaf || "",
        municipiof: doc?.municipiof || "",
        estadof: doc?.estadof || "",
        cpf: doc?.cpf || "",
        atencionf: doc?.atencionf || "",
        domicilio: doc?.domicilio || false,
        calle: doc?.calle || "",
        numeroext: doc?.numeroext || "",
        numeroint: doc?.numeroint || "",
        colonia: doc?.colonia || "",
        municipio: doc?.municipio || "",
        estado: doc?.estado || "",
        cp: doc?.cp || "",
        atencion: doc?.atencion || "",
        files: doc?.files ? doc.files.map(filePath => {
          const parts = filePath.split('/');
          return parts.length > 2 ? parts.slice(-1)[0] : filePath; // Solo conservar el último elemento
      }) : null
    }
}

export function validationSchemaalta() {
    return Yup.object({
        nombre: Yup.string().required(true),
        cedulamed: Yup.number().required(true),
        asesor: Yup.string().email(true).required(true),
        email: Yup.string().email(true).required(true),
        emailalternativo: Yup.string().email(true),
        telconsultorio: Yup.number().required(true),
        telpersonal: Yup.number().required(true),
        

    
        especialidad: Yup.bool(),
        nombreespecialidad: Yup.string().when('especialidad', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          cedulaesp: Yup.number().when('especialidad', {
            is: true,
            then: () => Yup.number().required(true),
            otherwise: () => Yup.number().notRequired()
          }),


          factura: Yup.bool(),
          razonsocial: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          rfc: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          callef: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          numeroextf: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          numerointf: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().notRequired(),
            otherwise: () => Yup.string().notRequired()
          }),
          coloniaf: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          municipiof: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          estadof: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),
          cpf: Yup.number().when('factura', {
            is: true,
            then: () => Yup.number().required(true),
            otherwise: () => Yup.number().notRequired()
          }),
          atencionf: Yup.string().when('factura', {
            is: true,
            then: () => Yup.string().required(true),
            otherwise: () => Yup.string().notRequired()
          }),

        domicilio: Yup.bool(),
        calle: Yup.string().when('domicilio', {
          is: true,
          then: () => Yup.string().required(true),
          otherwise: () => Yup.string().notRequired()
        }),
        numeroext: Yup.string().when('domicilio', {
          is: true,
          then: () => Yup.string().required(true),
          otherwise: () => Yup.string().notRequired()
        }),
        numeroint: Yup.string().when('domicilio', {
          is: true,
          then: () => Yup.string().notRequired(),
          otherwise: () => Yup.string().notRequired()
        }),
        colonia: Yup.string().when('domicilio', {
          is: true,
          then: () => Yup.string().required(true),
          otherwise: () => Yup.string().notRequired()
        }),
        municipio: Yup.string().when('domicilio', {
          is: true,
          then: () => Yup.string().required(true),
          otherwise: () => Yup.string().notRequired()
        }),
        estado: Yup.string().when('domicilio', {
          is: true,
          then: () => Yup.string().required(true),
          otherwise: () => Yup.string().notRequired()
        }),
        cp: Yup.number().when('domicilio', {
          is: true,
          then: () => Yup.number().required(true),
          otherwise: () => Yup.number().notRequired()
        }),
        atencion: Yup.string().when('domicilio', {
          is: true,
          then: () => Yup.string().required(true),
          otherwise: () => Yup.string().notRequired()
        }),

        // files: Yup.array().min(1).required(true)
    })
}