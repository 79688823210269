import React, { useState }  from 'react'
import { Form, Container } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './InydeForm.form'
import { Bioequal } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./InydeForm.scss";
import "./ConfirmWiithReason";
import ConfirmWithReason from './ConfirmWiithReason'
import {ENV} from './../../../../../utils/constants'

const equalController = new Bioequal();

export function InydeBioequalForm(props) {
    const { onClose, onReload, bioequal } = props;
    const { accessToken } = useAuth();
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

    const formik = useFormik({
        initialValues: initialValues(bioequal),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const data = {
                    restringida: formValue.restringida,
                };

                if (!bioequal) {
                    console.log("no deberia de pasar")
                   
                    // await equalController.createBioequal(accessToken, data);
                } else {
                    await equalController.UpdateInyde(accessToken, bioequal._id, data);
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });


    const handleCancel = async (reason) => {
        try {
          await equalController.CancelBioequal(accessToken, bioequal._id, reason)
          onClose();
          onReload();
          onOpenCloseConfirm();
        } catch (error) {
          console.error(error)
        }
      }

      const openDesactivateActivateConfirm = () => {
        setConfirmMessage(`Cancelar Formula Bioequal ${bioequal.folio}`);
        onOpenCloseConfirm();
      }

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='cotizacion-principal_info'>
                {bioequal.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{bioequal.folio}</span></p>}
                {bioequal.asesor && <p>Correo Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesor}</span></p>}
                {bioequal.asesornom && <p>Nombre de Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesornom}</span></p>}
                {bioequal.cardcode && <p>Cardcode de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cardcode}</span></p>}
                {bioequal.cliente && <p>Nombre de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cliente}</span></p>}
                {bioequal.especialidad && <p>Especialidad: <span className='cotizacion-principal_info-nombre'>{bioequal.especialidad}</span></p>}
                {bioequal.genero && <p>Genero del paciente: <span className='cotizacion-principal_info-nombre'>{bioequal.genero}</span></p>}
                {bioequal.base && <p>Base: <span className='cotizacion-principal_info-nombre'>{bioequal.base}</span></p>}
                {bioequal.viauso && <p>Vía de administración: <span className='cotizacion-principal_info-nombre'>{bioequal.viauso}</span></p>}
                {bioequal.activos && <p>Activos: <span className='cotizacion-principal_info-nombre'>{bioequal.activos}</span></p>}
                {bioequal.padecimiento && <p>Padecimiento: <span className='cotizacion-principal_info-nombre'>{bioequal.padecimiento}</span></p>}
                {bioequal.modo && <p>Modo de uso: <span className='cotizacion-principal_info-nombre'>{bioequal.modo}</span></p>}
                {bioequal.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(bioequal.created_at).toLocaleDateString()}</span></p>}
                {bioequal.files?.length > 0 && (
                    bioequal.files.map((file, index) => (
                    <a href={ENV.DOCTORDOCS+"/"+file} download={ENV.DOCTORDOCS+file} ><p>Firma del médico: <span className='cotizacion-principal_info-created_at'>{ENV.DOCTORDOCS}/{file}</span></p></a>
                    ))
                )}  
                <Form.Checkbox
                name="restringida"
                label="Fórmula restringida"
                onChange={(_, data) =>
                formik.setFieldValue('restringida', data.checked)
                }
                checked={formik.values.restringida}
                error={formik.errors.restringida}
                />  	


            </Container>
            
                 <Form.Checkbox
                name="sIyD"
                label="Aprobar"
                onChange={(_, data) =>
                formik.setFieldValue('sIyD', data.checked)
                }
                checked={formik.values.sIyD}
                error={formik.errors.sIyD}
            />
          
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
            <Form.Button type='button' color='red' fluid onClick={openDesactivateActivateConfirm} loading={formik.isSubmitting}>
                Cancelar
            </Form.Button>
            {/* <Confirm open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={handleCancel} content={confirmMessage} size='mini' /> */}
            <ConfirmWithReason open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={handleCancel} content={confirmMessage} size='mini'></ConfirmWithReason>
        </Form>
    )
}
