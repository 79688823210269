import React, {useState} from 'react'
import { BasicModal } from '../../../../components/Shared'
import { Tab } from 'semantic-ui-react'
import "./Ope.scss";
import {OpeBioequalForm, ListBioequalOpe} from "../../../../components/Admin/Bioequal/Ope"

export function OpeBioequal() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);
  const panes=[
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListBioequalOpe actividad="nueva" reload={reload} onReload={onReload}/>
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <div className='cotizacion-page'>
  
        <Tab menu={{secondary: true}} panes={panes}/> 
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title='Crear cotización Bioequal'>
        <OpeBioequalForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  )
}
