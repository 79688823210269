import React, { useState } from 'react'
import {Form, Container, TableRow, TableBody, TableHeader, TableHeaderCell, Table, TableCell} from 'semantic-ui-react';
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './AsesorForm.form'
import { Bioelite } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import ConfirmWithReason from './ConfirmWiithReason'
import "./AsesorForm.scss";

const eliteController = new Bioelite();

export function AsesorBioeliteForm(props) {
    const { onClose, onReload, Bioelite } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

    const formik = useFormik({
        initialValues: initialValues(Bioelite, CorreoAsesor, nombreCompleto),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                console.log("entre");

                if (!Bioelite) {
                    console.log("Esto no deberia de pasar")
                    
                } else {
                  await eliteController.UpdateAsesor(accessToken, Bioelite._id);
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });
    
    const handleCancel = async (reason) => {
        try {
          await eliteController.CancelAsesorBioelite(accessToken, Bioelite._id, reason)
          onClose();
          onReload();
          onOpenCloseConfirm();
        } catch (error) {
          console.error(error)
        }
      }

      const openDesactivateActivateConfirm = () => {
        setConfirmMessage(`Cancelar Formula Bioelite ${Bioelite.folio}`);
        onOpenCloseConfirm();
      }

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
<Container className='cotizacion-principal_info'>
                {Bioelite.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{Bioelite.folio}</span></p>}
                {Bioelite.asesor && <p>Correo Asesor: <span className='cotizacion-principal_info-dxp'>{Bioelite.asesor}</span></p>}
                {Bioelite.asesornom && <p>Nombre de Asesor: <span className='cotizacion-principal_info-dxp'>{Bioelite.asesornom}</span></p>}
                {Bioelite.cardcode && <p>Cardcode de Cliente: <span className='cotizacion-principal_info-nombre'>{Bioelite.cardcode}</span></p>}
                {Bioelite.cliente && <p>Nombre de Cliente: <span className='cotizacion-principal_info-nombre'>{Bioelite.cliente}</span></p>}
                {Bioelite.especialidad && <p>Especialidad: <span className='cotizacion-principal_info-nombre'>{Bioelite.especialidad}</span></p>}
                {Bioelite.base && <p>Base: <span className='cotizacion-principal_info-nombre'>{Bioelite.base}</span></p>}
                {Bioelite.activos && <p>Activos: <span className='cotizacion-principal_info-nombre'>{Bioelite.activos}</span></p>}
                {Bioelite.padecimiento && <p>Padecimiento: <span className='cotizacion-principal_info-nombre'>{Bioelite.padecimiento}</span></p>}
                {Bioelite.caducidad && <p>Caducidad en meses: <span className='cotizacion-principal_info-nombre'>{Bioelite.caducidad}</span></p>}
                {Bioelite.motivo && <p>Motivo de cancelación: <span className='cotizacion-principal_info-nombre'>{Bioelite.motivo}</span></p>}
                {Bioelite.observaciones && <p>Observaciones: <span className='cotizacion-principal_info-nombre'>{Bioelite.observaciones}</span></p>}
                {Bioelite.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(Bioelite.created_at).toLocaleDateString()}</span></p>} 
                <Container className='precio_unitario'>
                  <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell>Presentaciones</TableHeaderCell>
                        <TableHeaderCell>Precio</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    { Bioelite.base === 'Jarabe' ? (

                      <TableBody>
                        <TableRow>
                          <TableCell>30 ml</TableCell>
                          <TableCell>{Bioelite.precio30}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>50 ml</TableCell>
                          <TableCell>{Bioelite.precio50}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>120 ml</TableCell>
                          <TableCell>{Bioelite.precio120}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>240 ml</TableCell>
                          <TableCell>{Bioelite.precio240}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>1 L</TableCell>
                          <TableCell>{Bioelite.precio1L}</TableCell>
                        </TableRow>
                    </TableBody>
                      ) : 
                      <TableBody>
                      <TableRow>
                        <TableCell>30 Cápsulas</TableCell>
                        <TableCell>{Bioelite.precio30caps}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>60 Cápsulas</TableCell>
                        <TableCell>{Bioelite.precio60caps}</TableCell>
                      </TableRow>
                  </TableBody>
                    }
                  </Table>
                </Container>
            </Container>
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
            <Form.Button type='button' color='red' fluid onClick={openDesactivateActivateConfirm} loading={formik.isSubmitting}>
                Cancelar
            </Form.Button>
            <ConfirmWithReason open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={handleCancel} content={confirmMessage} size='mini'></ConfirmWithReason>
        </Form>
    )
}
