/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Bioelite } from '../../../../../api';
import { size, map } from 'lodash';
import { Loader, Pagination, Search, Popup } from 'semantic-ui-react';
import { OpeBioeliteItem } from '../OpeBioeliteItem/OpeBioeliteItem';


const BioeliteController = new Bioelite();

export function ListBioeliteOpe(props) {
  const { reload, onReload, onClose } = props;
  const [Bioelites, setBioelites] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const fetchBioelites = async (page) => {
    try {
      const response = await BioeliteController.getBioelites({ page, limit: 10 });
      return response;
    } catch (error) {
      console.error(error);
      return { docs: [], limit: 0, page: 0, pages: 0, totalPages: 0 };
    }
  };

  const loadBioelites = async () => {
    const response = await fetchBioelites(page);
    setBioelites(response.docs);
    setPagination({
      limit: response.limit,
      page: response.page,
      pages: response.pages,
      total: response.totalPages,
    });
  };

  const loadAllBioelites = async () => {
    let allBioelites = [];
    for (let i = 1; i <= pagination.total; i++) {
      const response = await fetchBioelites(i);
      allBioelites = allBioelites.concat(response.docs);
    }
    return allBioelites;
  };


  useEffect(() => {
    loadBioelites();
  }, [page, reload]);

  useEffect(() => {
    if (searchTerm) {
      (async () => {
        const allBioelites = await loadAllBioelites();
        setBioelites(allBioelites);
      })();
    } else {
      loadBioelites();
    }
  }, [searchTerm, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  const handleSearchChange = (_, { value }) => {
    setSearchTerm(value);
  };

  const filterBioelites = () => {
    if (!searchTerm) {
      return Bioelites;
    } else {
      return Bioelites.filter(Bioelite =>
        (Bioelite.folio && Bioelite.folio.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (Bioelite.cliente && Bioelite.cliente.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (Bioelite.cardcode && Bioelite.cardcode.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  };


  if (!Bioelites) return <Loader active inline="centered" />;
  if (size(Bioelites) === 0) return "No hay cotizaciones";

  return (
    <div className='list-cotizaciones'>
      <Popup trigger={<Search
        onSearchChange={handleSearchChange}
        value={searchTerm}
        placeholder="Buscar..."
        showNoResults={false}
      />}
        header='Busca por:'
        content='Folio, Nombre ó CardCode de Cliente'
      />
      <br />
      {map(filterBioelites(), Bioelite => (
        <OpeBioeliteItem key={Bioelite._id} Bioelite={Bioelite} onReload={onReload} onClose={onClose} />
      ))}
      <div className='list-cotizaciones__pagination'>
        <Pagination
          totalPages={pagination.total}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
