import * as Yup from "yup";

export function initialValues(equal) {
    return {
        cliente: equal?.cliente || "",
        asesor: equal?.asesor || "",
        asesornom: equal?.asesornom || "",
        padecimiento: equal?.padecimiento || "",
        base: equal?.base || "",
        activos: equal?.activos || "",
        especialidad: equal?.especialidad || "",
        cardcode: equal?.cardcode || "",
        consentimiento: equal?.consentimiento || false,
        restringida: equal?.restringida || false,
    }
}

export function validationSchema() {
    return Yup.object({
        sIyD: Yup.boolean()
        .oneOf([true])
        .required('Si quieres aceptar la formula debes rellenar la casilla Aprobar'),
        });
}