import React, { useState }  from 'react'
import { Form, Container, Table, TableRow, TableBody, TableCell, TableHeader, TableHeaderCell } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './InydeForm.form'
import { Bioelite } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./InydeForm.scss";
import ConfirmWithReason from './ConfirmWiithReason'

const eliteController = new Bioelite();

export function InydeBioeliteForm(props) {
    const { onClose, onReload, Bioelite } = props;
    const { accessToken } = useAuth();
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

    const formik = useFormik({
        initialValues: initialValues(Bioelite),
        validationSchema: validationSchema(Bioelite),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const data = {
                    base: Bioelite.base,
                    precio30 : formValue.precio30,
                    precio50 : formValue.precio50,
                    precio120 : formValue.precio120,
                    precio240 : formValue.precio240,
                    precio1L : formValue.precio1L,
                    precio30caps : formValue.precio30caps,
                    precio60caps : formValue.precio60caps,
                    caducidad : formValue.caducidad,
                    observaciones: formValue.observaciones,
                    activos: formValue.activos,
                }

                if (!Bioelite) {
                    console.log("no deberia de pasar")
                   
                    // await equalController.createBioelite(accessToken, data);
                } else {
                    await eliteController.UpdateInyde(accessToken, Bioelite._id, data);
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const handleSave = async () => {
        try {
        const data = {
            precio30 : formik.values.precio30,
            precio50 : formik.values.precio50,
            precio120 : formik.values.precio120,
            precio240 : formik.values.precio240,
            precio1L : formik.values.precio1L,
            precio30caps : formik.values.precio30caps,
            precio60caps : formik.values.precio60caps,
            caducidad : formik.values.caducidad,
            observaciones: formik.values.observaciones,
            activos: formik.values.activos
        }
        console.log(formik.values.precioUni);
        await eliteController.saveBioeliteInyde(accessToken, Bioelite._id, data)
        onClose();
        onReload();
        } catch (error) {
        console.error(error);
        }
    }

    const handleCancel = async (reason) => {
        try {
          await eliteController.CancelBioelite(accessToken, Bioelite._id, reason)
          onClose();
          onReload();
          onOpenCloseConfirm();
        } catch (error) {
          console.error(error)
        }
      }

      const openDesactivateActivateConfirm = () => {
        setConfirmMessage(`Cancelar Formula Bioelite ${Bioelite.folio}`);
        onOpenCloseConfirm();
      }

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='cotizacion-principal_info'>
                {Bioelite.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{Bioelite.folio}</span></p>}
                {/* {Bioelite.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(Bioelite.created_at).toLocaleDateString()}</span></p>} */}
                {Bioelite.asesor && <p>Correo Asesor: <span className='cotizacion-principal_info-dxp'>{Bioelite.asesor}</span></p>}
                {Bioelite.asesornom && <p>Nombre de Asesor: <span className='cotizacion-principal_info-dxp'>{Bioelite.asesornom}</span></p>}
                {Bioelite.cardcode && <p>Cardcode de Cliente: <span className='cotizacion-principal_info-nombre'>{Bioelite.cardcode}</span></p>}
                {Bioelite.cliente && <p>Nombre de Cliente: <span className='cotizacion-principal_info-nombre'>{Bioelite.cliente}</span></p>}
                {Bioelite.especialidad && <p>Especialidad: <span className='cotizacion-principal_info-nombre'>{Bioelite.especialidad}</span></p>}
                {Bioelite.genero && <p>Genero del paciente: <span className='cotizacion-principal_info-nombre'>{Bioelite.genero}</span></p>}
                {Bioelite.base && <p>Base: <span className='cotizacion-principal_info-nombre'>{Bioelite.base}</span></p>}
                {Bioelite.padecimiento && <p>Padecimiento: <span className='cotizacion-principal_info-nombre'>{Bioelite.padecimiento}</span></p>}
                <Form.TextArea label="Activos" name="activos" placeholder="Ingresa los activos para la fórmula, con su porcentaje" onChange={formik.handleChange} value={formik.values.activos} error={formik.errors.activos} />
            <Form.TextArea label="Observaciones" name="observaciones" placeholder="observaciones..." onChange={(e) => {formik.setFieldValue('observaciones', e.target.value)}} value={formik.values.observaciones} error={formik.errors.observaciones} />

            </Container>

            <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell>Presentaciones</TableHeaderCell>
                        <TableHeaderCell>Precio</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    { Bioelite.base === 'Jarabe' ? (
                        <TableBody>
                            <TableRow>
                                <TableCell>30 ml</TableCell>
                                <TableCell><Form.Input name='precio30' placeholder='0' onChange={(e) => {formik.setFieldValue('precio30', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.precio30} error={formik.errors.precio30} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>50 ml</TableCell>
                                <TableCell><Form.Input name='precio50' placeholder='0' onChange={(e) => {formik.setFieldValue('precio50', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.precio50} error={formik.errors.precio50} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>120 ml</TableCell>
                                <TableCell><Form.Input name='precio120' placeholder='0' onChange={(e) => {formik.setFieldValue('precio120', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.precio120} error={formik.errors.precio120} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>240 ml</TableCell>
                                <TableCell><Form.Input name='precio240' placeholder='0' onChange={(e) => {formik.setFieldValue('precio240', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.precio240} error={formik.errors.precio240} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>1 L</TableCell>
                                <TableCell><Form.Input name='precio1L' placeholder='0' onChange={(e) => {formik.setFieldValue('precio1L', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.precio1L} error={formik.errors.precio1L} /></TableCell>
                            </TableRow>
                        </TableBody>
                        ) : 
                        <TableBody>
                        <TableRow>
                            <TableCell>30 Cápsulas</TableCell>
                            <TableCell><Form.Input name='precio30caps' placeholder='0' onChange={(e) => {formik.setFieldValue('precio30caps', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.precio30caps} error={formik.errors.precio30caps} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>60 Cápsulas</TableCell>
                            <TableCell><Form.Input name='precio60caps' placeholder='0' onChange={(e) => {formik.setFieldValue('precio60caps', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.precio60caps} error={formik.errors.precio60caps} /></TableCell>
                        </TableRow>
                        </TableBody>
                        }
                </Table>
            </Container>
            <Form.Input label="Caducidad en meses" name="caducidad" placeholder="0" onChange={(e) => {formik.setFieldValue('caducidad', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.caducidad} error={formik.errors.caducidad} />
            <br/>
            <Form.Button type='button' primary fluid onClick={handleSave} loading={formik.isSubmitting}>
                Guardar
            </Form.Button>
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
            <Form.Button type='button' color='red' fluid onClick={openDesactivateActivateConfirm} loading={formik.isSubmitting}>
                Cancelar
            </Form.Button>
            <ConfirmWithReason open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={handleCancel} content={confirmMessage} size='mini'></ConfirmWithReason>
        </Form>
    )
}
