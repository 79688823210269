import React, {useState} from 'react'
import { BasicModal } from '../../../../components/Shared'
import { Button, Tab } from 'semantic-ui-react'
import "./asesor.scss";
import {AsesorBioequalForm, ListBioequalAsesor} from "../../../../components/Admin/Bioequal/Asesor"
import {AsesorCargaFirmaForm, AsesorCargaFirmaView} from "../../../../components/Admin/Bioequal/Asesor/AsesorCargaFirma"
import { useAuth } from "../../../../hooks";

export function AsesorBioequal() {
  const { user: { role } } = useAuth();
  
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [reload, setReload] = useState(false);
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseModal2 = () => setShowModal2((prevState) => !prevState);
  const onOpenCloseModal3 = () => setShowModal3((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);
  const panes=[
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListBioequalAsesor actividad="nueva" reload={reload} onReload={onReload}/>
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <div className='cotizacion-page'>
        <div className="cotizacion-page__buttons">
          <Button className='cotizacion-page__new' value="nueva" primary onClick={()=> onOpenCloseModal('nueva')}>Nueva Cotización</Button>
          <div className='right-buttons'>
            <Button className='cotizacion-page__new' value="nueva" primary onClick={()=> onOpenCloseModal2('nueva')}>Cargar firma</Button>
            {role === 'admin' ? (
            <Button className='cotizacion-page__new' value="nueva" color='green' onClick={()=> onOpenCloseModal3('nueva')}>Ver firmas</Button>
          ) : ''}
          </div>
        </div>
        <Tab menu={{secondary: true}} panes={panes}/> 
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title='Crear cotización Bioequal'>
        <AsesorBioequalForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
      <BasicModal show={showModal2} close={onOpenCloseModal2} title='Carga firma médico'>
        <AsesorCargaFirmaForm onClose={onOpenCloseModal2} onReload={onReload} />
      </BasicModal>
      <BasicModal show={showModal3} close={onOpenCloseModal3} title='Lista de firmas médicos'>
        <AsesorCargaFirmaView onClose={onOpenCloseModal3} onReload={onReload} />
      </BasicModal>
    </>
  )
}
