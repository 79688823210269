import * as Yup from "yup";

export function initialValuesaltacome(doc) {
    return {
      dermazone: doc && doc.catalogos[0] || true,
      reve: doc && doc.catalogos[1] || true,
      bioelite: doc && doc.catalogos[2] || true,
      bioequal: doc && doc.catalogos[3] || false,
      omigenomics: doc && doc.catalogos[4] || false
    }
}

export function validationSchemaaltacome() {
    return Yup.object({
      cardcode: Yup.string().required(true),
    })
}