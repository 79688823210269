/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Bioequal } from '../../../../../api';
import { size, map } from 'lodash';
import { Loader, Pagination, Search, Popup } from 'semantic-ui-react';
import { OpeBioequalItem } from '../OpeBioequalItem/OpeBioequalItem';


const bioequalController = new Bioequal();

export function ListBioequalOpe(props) {
  const { reload, onReload, onClose } = props;
  const [bioequals, setBioequals] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const fetchBioequals = async (page) => {
    try {
      const response = await bioequalController.getBioequals({ page, limit: 10 });
      return response;
    } catch (error) {
      console.error(error);
      return { docs: [], limit: 0, page: 0, pages: 0, totalPages: 0 };
    }
  };

  const loadBioequals = async () => {
    const response = await fetchBioequals(page);
    setBioequals(response.docs);
    setPagination({
      limit: response.limit,
      page: response.page,
      pages: response.pages,
      total: response.totalPages,
    });
  };

  const loadAllBioequals = async () => {
    let allBioequals = [];
    for (let i = 1; i <= pagination.total; i++) {
      const response = await fetchBioequals(i);
      allBioequals = allBioequals.concat(response.docs);
    }
    return allBioequals;
  };


  useEffect(() => {
    loadBioequals();
  }, [page, reload]);

  useEffect(() => {
    if (searchTerm) {
      (async () => {
        const allBioequals = await loadAllBioequals();
        setBioequals(allBioequals);
      })();
    } else {
      loadBioequals();
    }
  }, [searchTerm, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  const handleSearchChange = (_, { value }) => {
    setSearchTerm(value);
  };

  const filterBioequals = () => {
    if (!searchTerm) {
      return bioequals;
    } else {
      return bioequals.filter(bioequal =>
        (bioequal.folio && bioequal.folio.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (bioequal.cliente && bioequal.cliente.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (bioequal.cardcode && bioequal.cardcode.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  };


  if (!bioequals) return <Loader active inline="centered" />;
  if (size(bioequals) === 0) return "No hay cotizaciones";

  return (
    <div className='list-cotizaciones'>
      <Popup trigger={<Search
        onSearchChange={handleSearchChange}
        value={searchTerm}
        placeholder="Buscar..."
        showNoResults={false}
      />}
        header='Busca por:'
        content='Folio, Nombre ó CardCode de Cliente'
      />
      <br />
      {map(filterBioequals(), bioequal => (
        <OpeBioequalItem key={bioequal._id} bioequal={bioequal} onReload={onReload} onClose={onClose} />
      ))}
      <div className='list-cotizaciones__pagination'>
        <Pagination
          totalPages={pagination.total}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
