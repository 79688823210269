import React, {useState} from 'react'
// import { BasicModal } from '../../../components/Shared'
import { Tab } from 'semantic-ui-react'
import "./altaclientecome.scss";
// import {AltaClienteForm} from "../../../components/Admin/Asesor"
import { ListAltaClienteCome } from '../../../components/Admin/Come/ListAltaClienteCome/ListAltaClienteCome';

export function Altaclientecome() {
  // const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  // const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);
  const panes=[
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListAltaClienteCome reload={reload} onReload={onReload}/>
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <div className='cliente-page'>
        <Tab menu={{secondary: true}} panes={panes}/> 
      </div>

    </>
  )
}
