import React from 'react'
import { Form, Container} from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './AsesorForm.form'
import { Bioelite } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./AsesorForm.scss";

const eliteController = new Bioelite();

const especialidades = [
    { key: "ANESTESIOLOGIA", text: "ANESTESIOLOGIA", value: "ANESTESIOLOGIA" },
    { key: "ANGEOLOG", text: "ANGEOLOG", value: "ANGEOLOG" },
    { key: "BARIATRA", text: "BARIATRA", value: "BARIATRA" },
    { key: "CARDIOLOGO", text: "CARDIOLOGO", value: "CARDIOLOGO" },
    { key: "CIRUJANOPLASTICO", text: "CIRUJANO PLASTICO", value: "CIRUJANOPLASTICO" },
    { key: "CLINICASPA", text: "CLINICA/SPA", value: "CLINICA/SPA" },
    { key: "COSMEATRA", text: "COSMEATRA", value: "COSMEATRA" },
    { key: "DENTISTA", text: "DENTISTA", value: "DENTISTA" },
    { key: "DERMACET", text: "DERMA CET", value: "DERMACET" },
    { key: "ENDOCRINOLOGO", text: "ENDOCRINOLOGO", value: "ENDOCRINOLOGO" },
    { key: "EPIDEMIOLOGO", text: "EPIDEMIOLOGO", value: "EPIDEMIOLOGO" },
    { key: "ESTETICAYLONGEVIDAD", text: "ESTETICA Y LONGEVIDAD", value: "ESTETICAYLONGEVIDAD" },
    { key: "ESTOMATOLOGO", text: "ESTOMATOLOGO", value: "ESTOMATOLOGO" },
    { key: "GASTROENTEROLOGO", text: "GASTROENTEROLOGO", value: "GASTROENTEROLOGO" },
    { key: "GERIATRIA", text: "GERIATRIA", value: "GERIATRIA" },
    { key: "GINECOLOGO", text: "GINECOLOGO", value: "GINECOLOGO" },
    { key: "HOMEOPATA", text: "HOMEOPATA", value: "HOMEOPATA" },
    { key: "MEDICINADELDEPORTE", text: "MEDICINA DEL DEPORTE", value: "MEDICINADELDEPORTE" },
    { key: "MEDICINAINTERNA", text: "MEDICINA INTERNA", value: "MEDICINAINTERNA" },
    { key: "MEDICODERMATOLOGO", text: "MEDICO DERMATOLOGO", value: "MEDICODERMATOLOGO" },
    { key: "MEDICOGENERAL", text: "MEDICO GENERAL", value: "MEDICOGENERAL" },
    { key: "MEDICO UROLOGO", text: "MEDICO UROLOGO", value: "MEDICOUROLOGO" },
    { key: "MEDICO VETERINARIO", text: "MEDICO VETERINARIO", value: "MEDICOVETERINARIO" },
    { key: "NUTRIOLOGO", text: "NUTRIOLOGO", value: "NUTRIOLOGO" },
    { key: "ODONTOLOGIA", text: "ODONTOLOGIA", value: "ODONTOLOGIA" },
    { key: "OFTALMOLOGO", text: "OFTALMOLOGO", value: "OFTALMOLOGO" },
    { key: "OTORINOLARINGOLOGO", text: "OTORINOLARINGOLOGO", value: "OTORINOLARINGOLOGO" },
    { key: "PEDIATRA", text: "PEDIATRA", value: "PEDIATRA" },
    { key: "PODOLOGO", text: "PODOLOGO", value: "PODOLOGO" },
    { key: "PSIQUIATRIA", text: "PSIQUIATRIA", value: "PSIQUIATRIA" },
    { key: "RESIDENTEDERMA", text: "RESIDENTE DERMA", value: "RESIDENTEDERMA" },
    { key: "REUMATOLOGO", text: "REUMATOLOGO", value: "REUMATOLOGO" },
    { key: "TRAUMATOLOGO", text: "TRAUMATOLOGO", value: "TRAUMATOLOGO" },
];

const bases = [
    { key: "Capsula", text: "Cápsula", value: "Capsula" },
    { key: "Jarabe", text: "Jarabe", value: "Jarabe" }
];

export function AsesorBioeliteForm(props) {
    const { onClose, onReload, elite } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;

    const formik = useFormik({
        initialValues: initialValues(elite, CorreoAsesor, nombreCompleto),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const data = {
                    cliente: formValue.cliente,
                    asesor: CorreoAsesor,
                    asesornom: nombreCompleto,
                    base: formValue.base,
                    activos: formValue.activos,
                    especialidad: formValue.especialidad,
                    padecimiento: formValue.padecimiento,
                    cardcode: formValue.cardcode,
                };
                if (!elite) {
                    await eliteController.createBioelite(accessToken, data);
                } else {
                   console.log("Esto no deberia de pasar")
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });
    
    const fechaActual = new Date().toLocaleDateString('es-ES');

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='form-cotizacion__primer'>
                <Form.Group className="form-group">
                    <p>Asesor: <span>{nombreCompleto}</span></p>
                    <p>Fecha: <span>{fechaActual}</span></p>
                </Form.Group>
                <Form.Input label="Cardcode" name="cardcode" placeholder="Cardcode del médico responsable" maxLength={6} onChange={formik.handleChange} value={formik.values.cardcode} error={formik.errors.cardcode} />
                <Form.Input label="Médico responsable" name="cliente" placeholder="Nombre del médico responsable" onChange={formik.handleChange} value={formik.values.cliente} error={formik.errors.cliente} />
                <Form.Dropdown label="Nombre especialidad" placeholder="Seleccione una especialidad" name="especialidad" options={especialidades} selection onChange={(_, data) => formik.setFieldValue("especialidad", data.value)} value={formik.values.especialidad} error={formik.errors.especialidad} />
                <Form.Dropdown label="Base" placeholder="Base" options={bases} selection onChange={(_, data) => formik.setFieldValue("base", data.value)} value={formik.values.base} error={formik.errors.base} />
                <Form.TextArea label="Activos" name="activos" placeholder="Ingresa los activos para la fórmula, con su porcentaje" onChange={formik.handleChange} value={formik.values.activos} error={formik.errors.activos} />
                <Form.Input label="Padecimiento" name="padecimiento" placeholder="Padecimiento o intención de uso (p. ej. Ovario poliquístico o aparato/equipo)" onChange={formik.handleChange} value={formik.values.padecimiento} error={formik.errors.padecimiento} />
            </Container>
            <br></br>
            <Container>
                <Form.Button type='submit' primary fluid loading={formik.isSubmitting} >
                    Enviar Cotización
                </Form.Button>
            </Container>
        </Form>
    )
}
