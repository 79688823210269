import React, { useState } from 'react'
import { Button, Icon, Label, Confirm } from 'semantic-ui-react';
import { BasicModal } from '../../../../Shared';
import "./AsesorItem.scss";
import { Bioelite } from '../../../../../api';
import { useAuth } from '../../../../../hooks'
import { AsesorView, AsesorBioeliteForm } from '../AsesorBioeliteForm2';
const equalController = new Bioelite();

export function AsesorBioeliteItem(props) {
  const { Bioelite, onReload } = props;
  const { accessToken } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const date = new Date(Bioelite.created_at);
  const createdate = date.toLocaleDateString();
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseModal2 = () => setShowModal2((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openVerDoc = () => {
    setTitleModal(`Folio de Bioelite: ${Bioelite.folio}`);
    onOpenCloseModal();
  }
  
  const openFormBioelite = () => {
    setTitleModal(`Folio de Cotización Bioelite: ${Bioelite.folio}`);
    onOpenCloseModal2();
  }

  const openDesactivateActivateConfirm = () => {
    setConfirmMessage(`Borrar Formula Bioelite ${Bioelite.folio}`);
    onOpenCloseConfirm();
  }

  const CancelCliente = async () => {
    try {
      await equalController.CancelBioeliteAsesor(accessToken, Bioelite._id)
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error)
    }
}

  let contentView = <AsesorView onClose={onOpenCloseModal} onReload={onReload} Bioelite={Bioelite} />;
  let contentForm = <AsesorBioeliteForm onClose={onOpenCloseModal2} onReload={onReload} Bioelite={Bioelite} />;

  let getStatusGeneralColor;

  if (Bioelite.StatusGeneral === 'Pendiente') {
    getStatusGeneralColor = 'orange'
  } else if (Bioelite.StatusGeneral === 'Cancelado') {
    getStatusGeneralColor = 'red';
  } else if (Bioelite.StatusGeneral === 'Finalizado') {
    getStatusGeneralColor = 'green'
  }

  

  return (
    <>
      <div className='cotizacion-item'>
         <div className='column'>
          <p className='cotizacion-item__info'>
            <span className='cotizacion-item__info-label'>Folio Cliente:</span>
            <span className='cotizacion-item__info-valor'>{Bioelite.folio}</span><br />
            <span className='cotizacion-item__info-label'>Creado:</span>
            <span className='cotizacion-item__info-valor'>{createdate}</span><br/>
          </p>
        </div>
        <div className='column'>
          <p className='cotizacion-item__info'>
            <span className='cotizacion-item__info-label'>Cliente:</span>
            <span className='cotizacion-item__info-valor'>{Bioelite.cliente}</span>
            <span className='cotizacion-item__info-label'>Cardcode:</span>
            <span className='cotizacion-item__info-valor'>{Bioelite.cardcode}</span>
          </p>
        </div>
            
        <div className='column'>
          <p className='cotizacion-item__info'>
            <label className='estatus_gn'>Estatus General: <Label className={`cotizacion-item__info-statusinde`} color={getStatusGeneralColor}>
              {Bioelite.StatusGeneral}
            </Label></label>
            <label className='estatus_gn'>Estatus Investigación y Desarrollo: <Label className={`cotizacion-item__info-statusinde`} color={Bioelite.StatusGeneral === "Cancelado" ? "red" : (Bioelite.sIyD ? 'green' : 'orange')}>
              { Bioelite.StatusGeneral === "Cancelado" ? "Cancelado" : (Bioelite.sIyD ? 'Finalizado' : 'Pendiente')}
            </Label></label>
            <label className='estatus_gn'>Estatus Confirmacion de alta asesor: <Label className={`cotizacion-item__info-statusinde`} color={Bioelite.StatusGeneral === "Cancelado" ? "red" : (Bioelite.sAse ? 'green' : 'orange')}>
              { Bioelite.StatusGeneral === "Cancelado" ? "Cancelado" : (Bioelite.sAse ? 'Finalizado' : 'Pendiente')}
            </Label></label>
            <label className='estatus_gn'>Estatus Operación Final: <Label className={`cotizacion-item__info-statusinde`} color={Bioelite.StatusGeneral === "Cancelado" ? "red" : (Bioelite.sOpe ? 'green' : 'orange')}>
              { Bioelite.StatusGeneral === "Cancelado" ? "Cancelado" : (Bioelite.sOpe ? 'Finalizado' : 'Pendiente')}
            </Label></label>
          </p>
        </div>
        <div style={{ display: 'flex', gap: '0' }}>
            {Bioelite.StatusGeneral !== 'Cancelado' ? (Bioelite.sIyD && !Bioelite.sAse && (
            <Button icon primary onClick={openFormBioelite}>
              <Icon name='edit'/>
            </Button>
              )) : ''}
            <Button icon primary onClick={openVerDoc}>
              <Icon name='eye' />
            </Button>
            {Bioelite.StatusGeneral !== 'Cancelado' ? (Bioelite.sOpcoti && !Bioelite.sAse && (
            <Button icon color='red' onClick={openDesactivateActivateConfirm}>
              <Icon name='trash alternate outline' />
            </Button>
          )) : ''}
        </div>
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        {contentView}
      </BasicModal>
      <BasicModal show={showModal2} close={onOpenCloseModal2} title={titleModal}>
        {contentForm}
      </BasicModal>
      <Confirm open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={CancelCliente} content={confirmMessage} size='mini' />
    </>
  )
}
