import React from 'react'
import { Container } from 'semantic-ui-react';
import {ENV} from './../../../../../utils/constants'


export function InydeView(props) {
  const { bioequal } = props;

  return (
    <div className='cotizacion-principal'>
            <Container className='cotizacion-principal_info'>
                {bioequal.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{bioequal.folio}</span></p>}
                {bioequal.asesor && <p>Correo Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesor}</span></p>}
                {bioequal.asesornom && <p>Nombre de Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesornom}</span></p>}
                {bioequal.cardcode && <p>Cardcode de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cardcode}</span></p>}
                {bioequal.cliente && <p>Nombre de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cliente}</span></p>}
                {bioequal.especialidad && <p>Especialidad: <span className='cotizacion-principal_info-nombre'>{bioequal.especialidad}</span></p>}
                {bioequal.genero && <p>Genero: <span className='cotizacion-principal_info-nombre'>{bioequal.genero}</span></p>}
                {bioequal.base && <p>Base: <span className='cotizacion-principal_info-nombre'>{bioequal.base}</span></p>}
                {bioequal.viauso && <p>Vía de administración: <span className='cotizacion-principal_info-nombre'>{bioequal.viauso}</span></p>}
                {bioequal.activos && <p>Activos: <span className='cotizacion-principal_info-nombre'>{bioequal.activos}</span></p>}
                {bioequal.padecimiento && <p>Padecimiento: <span className='cotizacion-principal_info-nombre'>{bioequal.padecimiento}</span></p>}
                {bioequal.modo && <p>Modo de uso: <span className='cotizacion-principal_info-nombre'>{bioequal.modo}</span></p>}
                {bioequal.motivo && <p>Motivo de cancelación: <span className='cotizacion-principal_info-nombre'>{bioequal.motivo}</span></p>}
                {bioequal.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(bioequal.created_at).toLocaleDateString()}</span></p>}
                <p>Restringida: { bioequal.restringida ? <span className='cotizacion-principal_info-created_at'>Sí</span> : <span className='cotizacion-principal_info-created_at'>No</span>}</p>
                {bioequal.files?.length > 0 && (
                    bioequal.files.map((file, index) => (
                    <a href={ENV.DOCTORDOCS+"/"+file} download={ENV.DOCTORDOCS+file} ><p>Firma del médico: <span className='cotizacion-principal_info-created_at'>{ENV.DOCTORDOCS}/{file}</span></p></a>
                    ))
                )}  
            </Container>
    </div>

  )
}
