import * as Yup from "yup";

export function initialValuess(ope){
    return{
        folio_Op: ope?.folio_IyD,
        email: ope?.email,
        comClie: ope?.comClie,
        asesor: ope?.asesor,
        precio1: ope?.precio1,
        precio2: ope?.precio2,
        precio3: ope?.precio3,
        precio4: ope?.precio4,
        precio5: ope?.precio5,
        precio6: ope?.precio6,
        precio7: ope?.precio7,
        precio8: ope?.precio8,
    };
}

export function validationSchemas(){
    return Yup.object({
        folio_Op: Yup.number().required(true),
    });
}