import { ENV } from "../utils";

export class Doctor {
    baseApi = ENV.BASE_API;
    
    async getDocByAsesor(asesor, params) {
      try {
          const pageFilter = `page=${params?.page || 1}`;
          const limitFilter = `limit=${params?.limit || 10}`;
          const email = `asesor=${asesor || ""}`;
          const url = `${this.baseApi}/${ENV.API_ROUTES.DOCS}?${email}&${pageFilter}&${limitFilter}`;
          const response = await fetch(url);
          const result = await response.json();
          if (response.status !== 200) throw result;
          return result;
      } catch (error) {
          throw error
      }
    }

    async getDocs(params) {
        try {
            const pageFilter = `page=${params?.page || 1}`;
            const limitFilter = `limit=${params?.limit || 10}`;
            const url = `${this.baseApi}/${ENV.API_ROUTES.DOCSCOME}?${pageFilter}&${limitFilter}`;
            const response = await fetch(url);
            const result = await response.json();
            if (response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error
        }
      }

    async createDoctor(accessToken, data) {
        console.log(data);
        try {
            const formData = new FormData();
            if (data.file && data.file.length > 0) {
                Array.from(data.file).forEach((file) => {
                  formData.append('documentos', file);  // Asegúrate de que es un objeto File
                });
              }
            
              // Adjuntar otros datos
              Object.keys(data).forEach((key) => {
                if (key !== 'file') {
                  formData.append(key, data[key]);
                }
              });
            const url = `${this.baseApi}/${ENV.API_ROUTES.DOCTOR}`
            const params = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: formData,
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 201) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async CancelDoctor(accessToken, id) {
      try {
          const url = `${this.baseApi}/${ENV.API_ROUTES.DOCTOR}/${id}`
          const params = {
              method: 'DELETE',
              headers: {
                  Authorization: `Bearer ${accessToken}`
              }
          };
          const response = await fetch(url, params);
          const result = await response.json();
          if (response.status !== 201) throw result;
          return result;
      } catch (error) {
          throw error;
      }
    }

    async CheckDoctor(accessToken, id) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.DOCTOR}/${id}`
            const params = {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 201) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async UpdateCatalogDoctor(accessToken, id, data) {
        console.log(data);
        console.log(id)
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.DOCTORCOME}/${id}`
            const params = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify({ data : data }),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error;
        }
       }
}