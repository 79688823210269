import noAvatar from "./jpg/default.jpg";
import { ReactComponent as LogoWhite } from "./svg/KAAPA_Logo-Positivo.svg";

const Icon = {
    LogoWhite,
};

const image = {
    noAvatar
};


export { Icon, image };