import * as Yup from "yup";

export function initialValues(elite) {
    return {
        codigo: elite?.codigo || '',
    }
}

export function validationSchema() {
    return Yup.object({
            codigo: Yup.string().required(true)
        });
}