import * as Yup from "yup";

export function initialValues(equal, email, nombreCompleto) {
    return {
        cliente: equal?.cliente || "",
        asesor: equal && equal.asesor ? equal.asesor : email,
        asesornom: equal && equal.asesornom ? equal.asesornom : nombreCompleto,
        padecimiento: equal?.padecimiento || "",
        base: equal?.base || "",
        activos: equal?.activos || "",
        especialidad: equal?.especialidad || "",
        cardcode: equal?.cardcode || "",
        precioUni: equal?.precioUni || ''
    }
}

export function validationSchema() {
    return Yup.object({

})}