import { ENV } from "../utils";

export class Bioelite {
    baseApi = ENV.BASE_API;
    
    
    async createBioelite(accessToken, BioeliteData) {
      try {
          const data = BioeliteData;
          console.log(data);
          const url = `${this.baseApi}/${ENV.API_ROUTES.BIOELITE}`
          const params = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(data),
          }
          const response = await fetch(url, params)
          const result = await response.json()
          if (response.status !== 200) throw result;
          return result;
      } catch (error) {
          throw error;
      }
  }

  async getBioelitesByAsesor(asesor, params) {
    try {
        const pageFilter = `page=${params?.page || 1}`;
        const limitFilter = `limit=${params?.limit || 10}`;
        const email = `asesor=${asesor || ""}`;
        const url = `${this.baseApi}/${ENV.API_ROUTES.BIOELITES}?${email}&${pageFilter}&${limitFilter}`;
        const response = await fetch(url);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error
    }
  }
  
  async getBioelites(params) {
    try {
        const pageFilter = `page=${params?.page || 1}`;
        const limitFilter = `limit=${params?.limit || 10}`;
        const url = `${this.baseApi}/${ENV.API_ROUTES.BIOELITES}?${pageFilter}&${limitFilter}`;
        const response = await fetch(url);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error
    }
  }

  async CancelBioelite(accessToken, id, reason) {
    const data = {
        motivo: reason
    }
    console.log("esta data es: " + reason)
    try {
        const url = `${this.baseApi}/${ENV.API_ROUTES.INYDE}/${ENV.API_ROUTES.BIOELITE}/${id}`
        const params = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 201) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async CancelAsesorBioelite(accessToken, id, reason) {
    const data = {
        motivo: reason
    }
    console.log("esta data es: " + reason)
    try {
        const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.BIOELITE}/${id}`
        const params = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 201) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async saveBioeliteInyde(accessToken, id, bioequalData) {
    try {
        const data = bioequalData;
        console.log(bioequalData)
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
              formData.append(key, data[key]);
          })
        const url = `${this.baseApi}/${ENV.API_ROUTES.SAVEINYDE}/${ENV.API_ROUTES.BIOELITE}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async UpdateInyde(accessToken, id, BioeliteData) {
    try {
        const data = BioeliteData;
        console.log(data);
        const url = `${this.baseApi}/${ENV.API_ROUTES.INYDE}/${ENV.API_ROUTES.BIOELITE}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 201) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async UpdateAsesor(accessToken, id) {
    try {
    
        const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.BIOELITE}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 201) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async saveBioeliteOpe(accessToken, id, bioeliteData) {
    try {
        const data = bioeliteData;
    

        const url = `${this.baseApi}/${ENV.API_ROUTES.SAVEOPE}/${ENV.API_ROUTES.BIOELITE}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

async UpdateOpe(accessToken, id, bioeliteData) {
    try {
        const data = bioeliteData;
        const url = `${this.baseApi}/${ENV.API_ROUTES.OPE}/${ENV.API_ROUTES.BIOELITE}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}

}