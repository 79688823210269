import React from 'react'
import { Form, Container, Table, TableRow, TableBody, TableCell, TableHeader, TableHeaderCell } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './OpeForm.form'
import { Bioequal } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./OpeForm.scss";

const equalController = new Bioequal();

export function OpeBioequalForm2(props) {
    const { onClose, onReload, bioequal } = props;
    const { accessToken } = useAuth();

    const formik = useFormik({
        initialValues: initialValues(bioequal),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const data = {  
                    codigo: formValue.codigo,
                };
                if (!bioequal) {
                    console.log("no deberia de pasar")
                   
                    // await equalController.createBioequal(accessToken, data);
                } else {
                    await equalController.UpdateOpe2(accessToken, bioequal._id, data);
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });

    
    const handleSave = async () => {
        try {
        const data = {
            codigo : formik.values.codigo
        }
        console.log(formik.values.codigo);
        await equalController.saveBioequalOpe(accessToken, bioequal._id, data)
        onClose();
        onReload();
        } catch (error) {
        console.error(error);
        }
    }
    console.log(formik.values.precioUni);
    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='cotizacion-principal_info'>
                {bioequal.folio && <p>Folio: <span className='cotizacion-principal_info-dxp'>{bioequal.folio}</span></p>}
                {bioequal.asesor && <p>Correo Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesor}</span></p>}
                {bioequal.asesornom && <p>Nombre de Asesor: <span className='cotizacion-principal_info-dxp'>{bioequal.asesornom}</span></p>}
                {bioequal.cardcode && <p>Cardcode de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cardcode}</span></p>}
                {bioequal.cliente && <p>Nombre de Cliente: <span className='cotizacion-principal_info-nombre'>{bioequal.cliente}</span></p>}
                {bioequal.especialidad && <p>Especialidad: <span className='cotizacion-principal_info-nombre'>{bioequal.especialidad}</span></p>}
                {bioequal.base && <p>Base: <span className='cotizacion-principal_info-nombre'>{bioequal.base}</span></p>}
                {bioequal.viauso && <p>Vía de administración: <span className='cotizacion-principal_info-nombre'>{bioequal.viauso}</span></p>}
                {bioequal.activos && <p>Activos: <span className='cotizacion-principal_info-nombre'>{bioequal.activos}</span></p>}
                {bioequal.padecimiento && <p>Padecimiento: <span className='cotizacion-principal_info-nombre'>{bioequal.padecimiento}</span></p>}
                {bioequal.modo && <p>Modo de uso: <span className='cotizacion-principal_info-nombre'>{bioequal.modo}</span></p>}
                <p>Restringida: <span className='cotizacion-principal_info-nombre'>{bioequal.restringida ? 'Si' : 'No'}</span></p>
                {bioequal.created_at && <p>Fecha de Creación: <span className='cotizacion-principal_info-created_at'>{new Date(bioequal.created_at).toLocaleDateString()}</span></p>}
            </Container>
            <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell>Presentaciones</TableHeaderCell>
                        <TableHeaderCell>Precio</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                        <TableCell>Precio Unitario</TableCell>
                        <TableCell>{bioequal.precioUni}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Container>
            <Form.Input label="Código de Formula Bioequal" name="codigo" placeholder="Código de la Formula Bioequal" onChange={formik.handleChange} value={formik.values.codigo} error={formik.errors.codigo} />
            <br></br>
            <Form.Button type='button' primary fluid onClick={handleSave} loading={formik.isSubmitting}>
                Guardar
            </Form.Button>
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
        </Form>
    )
}
