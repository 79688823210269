import React, { useState } from 'react'
import { Button, TextArea, Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'

function ConfirmWithReason({ open, onCancel, onConfirm, content, size }) {
  const [reason, setReason] = useState('')

  const handleConfirm = () => {
    console.log("confirmo")
    onConfirm(reason)
    setReason('')
  }

  const handleCancel = () => {
    onCancel()
    setReason('')
  }

  return (
    <Modal open={open} size={size} onClose={handleCancel}>
      <Modal.Content>
        <p>{content}</p>
        <TextArea
          fluid
          placeholder="Ingrese el motivo"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          style={{ marginTop: '1rem', width: '100%'}}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button color='red' onClick={handleConfirm} disabled={!reason.trim()}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ConfirmWithReason.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'fullscreen'])
}

ConfirmWithReason.defaultProps = {
  size: 'mini'
}

export default ConfirmWithReason